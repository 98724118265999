/* -----------------------------
Switch
https://codepen.io/zzseba78/pen/PxwmeV
*/


.uk-switch {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 42px;
}

/* Hide default HTML checkbox */
.uk-switch input {
  display:none;
}
/* Slider */
.uk-switch-slider {
  background-color: $global-dark-grey-color;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 500px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .2s;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);

  /* Switch pointer */
  &:before {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    bottom: 2px;
    border-radius: 50%;
    transition-property: transform, box-shadow, background-color, width, height;
    transition-duration: .2s;
  }

  &:hover:before {
    background-color: $global-color;
  }
}

/* Slider active color */
input:checked + .uk-switch-slider {
  background-color: $global-secondary-background !important;
}
/* Pointer active animation */
input:checked + .uk-switch-slider:before {
  transform: translateX(20px);
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
  background-color: #f0506e;
}
input:checked + .uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
input:checked + .uk-switch-slider.uk-switch-big:before {
  transform: translateX(26px) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255,255,255,0.22);
}