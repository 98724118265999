// Name:            Heading
// Description:     Styles for headings
//
// Component:       `uk-heading-primary`
//                  `uk-heading-hero`
//                  `uk-heading-divider`
//                  `uk-heading-bullet`
//                  `uk-heading-line`
//
// ========================================================================




// Hooks
// ========================================================================

// @mixin hook-heading-small(){}
// @mixin hook-heading-medium(){}
 @mixin hook-heading-large(){
   font-weight: $light;
   letter-spacing: .01em;
   line-height: (80/70);
 }
// @mixin hook-heading-xlarge(){}
// @mixin hook-heading-2xlarge(){}
// @mixin hook-heading-primary(){}
// @mixin hook-heading-hero(){}
// @mixin hook-heading-divider(){}
// @mixin hook-heading-bullet(){}
// @mixin hook-heading-line(){}
// @mixin hook-heading-misc(){}


// Inverse
// ========================================================================


// @mixin hook-inverse-heading-small(){}
// @mixin hook-inverse-heading-medium(){}
// @mixin hook-inverse-heading-large(){}
// @mixin hook-inverse-heading-xlarge(){}
// @mixin hook-inverse-heading-2xlarge(){}
// @mixin hook-inverse-heading-primary(){}
// @mixin hook-inverse-heading-hero(){}
// @mixin hook-inverse-heading-divider(){}
// @mixin hook-inverse-heading-bullet(){}
// @mixin hook-inverse-heading-line(){}
