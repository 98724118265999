// Name:            Description list
// Description:     Styles for description lists
//
// Component:       `uk-description-list`
//
// Modifiers:       `uk-description-list-divider`
//
// ========================================================================


// Hooks
// ========================================================================


 @mixin hook-description-list-term(){
   display: inline;
   font-weight: normal;
 }
 @mixin hook-description-list-description(){
   display: inline;
   margin-right: 4px;
 }
// @mixin hook-description-list-divider-term(){}
// @mixin hook-description-list-misc(){}