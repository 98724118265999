

/*  ******************************************************************************

    Eigene Stile  */
.rs-control .rs-range-color {
  background-color: $global-emphasis-color;

  .scheme-a & {background-color: $color-A;}
  .scheme-b & {background-color: $color-B;}
  .scheme-c & {background-color: $color-C;}
  .scheme-d & {background-color: $color-D;}
  .scheme-e & {background-color: $color-E;}
  .scheme-f & {background-color: $color-F;}
  .scheme-g & {background-color: $color-G;}
  .scheme-y & {background-color: $color-Y;}
  .scheme-z & {background-color: $color-X;}
}
.rs-control .rs-path-color {
  background-color: $global-muted-background;
  }
.rs-control .rs-handle {
  background-color: blend-multiply(#8959C0, #A5B6C2);

  .scheme-a & {background: blend-multiply($color-A, $color-multiply);}
  .scheme-b & {background: blend-multiply($color-B, $color-multiply);}
  .scheme-c & {background: blend-multiply($color-C, $color-multiply);}
  .scheme-d & {background: blend-multiply($color-D, $color-multiply);}
  .scheme-e & {background: blend-multiply($color-E, $color-multiply);}
  .scheme-f & {background: blend-multiply($color-F, $color-multiply);}
  .scheme-g & {background: blend-multiply($color-G, $color-multiply);}
  .scheme-y & {background: blend-multiply($color-Y, $color-multiply);}
  .scheme-x & {background: blend-multiply($color-X, $color-multiply);}

}

.rs-control .rs-bg-color {
}
.rs-border {
  border: none;
}

.rs-tooltip-text {
  font-family: $global-font-family;
  color: #004078;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  transform: translateX(-30%);

  &:after {
    content: " %";
    position: absolute;
    left: 100%;
    margin-left: .05em
  }
}

/*  ****************************************

    RESULT Slider

 */
.slider-round-container {
  min-width: 80px; max-width: 180px;

  @include breakpoint(max-width $breakpoint-xsmall-max) {
    max-width: 100px
  }
}
.slider-round-result {
  pointer-events: none;
  cursor: pointer;


  &.rs-control .rs-handle {
    display: none;
  }
  .rs-tooltip-text {
    color: $global-color-dark;
    font-size: $global-small-font-size;

    @include breakpoint($breakpoint-small) {
      font-size: $global-medium-font-size;
      transform: translate(-20%,5%);
    }

  }

  .rs-container:before {
    content: "";
    display: block;
    width: 50%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    z-index: 10;
    border-radius: 100%;
    transition: all .2s ease-in;
    @include breakpoint($breakpoint-small) {
      width: 60%;
      height: 60%;
    }

  }

  &.yellow .rs-container:before {background-color: $yellow}
  &.green .rs-container:before{background-color: $green}
  &.red .rs-container:before {background-color: $red}

  .rs-path-color {
    transition: all .2s ease-in;

    background-color: mix(white, $global-primary-background, 20%);

    .card--eval:hover & {
      background-color: mix(white, $global-emphasis-color, 20%);
    }

  }

  &.yellow .rs-range-color {background-color: $yellow}
  &.green  .rs-range-color{background-color: $green}
  &.red .rs-range-color{background-color: $red}

  .rs-bg-color {
    transition: all .2s ease-in;
    background-color: $global-primary-background;
  }

  .card--eval:hover & .rs-bg-color {
    background-color: $global-emphasis-color;
  }
  .card--eval.inactive:hover & .rs-bg-color {
    background-color: mix(white, $global-emphasis-color, 4%);
  }
  .card--eval.inactive:hover & .rs-container:before {
    background-color: mix(white, $global-emphasis-color, 20%);
  }

  // Auf weiß stehend
  &.on-white {
    .rs-path-color {
    background-color: mix($global-muted-color, white, 15%);
    }
    .rs-bg-color {
      background-color: white;
    }
  }
}