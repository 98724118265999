.ce, [class*="ce-"] {



 //.link-cta1 {
 //  @extend .uk-button;
 //  @extend .uk-button-primary;
 //
 //}
 //
 // .link-cta2 {
 //   @extend .uk-button;
 //   @extend .uk-button-default;
 //
 // }
 //
 // .link-download, .link-external, .link-readmore {
 //   text-transform: uppercase;
 //   font-weight: 500;
 //   font-size: $global-tiny-font-size;
 //   letter-spacing: .15em;
 //
 // }

  //.link-download  {
  //  position: relative;
  //  display: inline-block;
  //  text-decoration: none;
  //
  //  &:after {
  //    content: "";
  //    display: inline-block;
  //    width: 1.05rem;
  //    height: 1.05rem;
  //    margin-left: .75em;
  //    margin-right: .1em;
  //    margin-bottom: -.4em;
  //    background: transparent inline-svg("download", $global-sand-color) no-repeat;
  //    background-size: contain;
  //  }
  //
  //  &:hover:after {
  //
  //    background: transparent inline-svg("download", $global-darkblue-color) no-repeat;
  //    background-size: contain;
  //  }
  //}

  //a[target="_blank"]:not([class*=link-download]):not([class*=link-cta]):not([class*=uk-button]) {
  //  position: relative;
  //  display: inline;
  //  text-decoration: none;
  //
  //  &:after {
  //    content: "";
  //    display: inline-block;
  //    width: 1.4rem;
  //    height: 1.4rem;
  //    margin-left: -.2em;
  //    margin-right: -.2em;
  //    margin-bottom: -.5em;
  //    background: transparent inline-svg("external", $global-sand-color) no-repeat;
  //    background-size: contain;
  //  }
  //
  //  &:hover:after {
  //    background: transparent inline-svg("external", $global-darkblue-color) no-repeat;
  //    background-size: contain;
  //  }
  //}


  //
  //
  //.link-readmore  {
  //  position: relative;
  //  display: inline-block;
  //  text-decoration: none;
  //  margin-top: 1rem;
  //
  //  &:focus {
  //    outline: none;
  //  }
  //
  //  &:after {
  //    content: "";
  //    display: block;
  //    width: 1.5rem;
  //    height: 1.5rem;
  //    margin-left: .75em;
  //    margin-right: .1em;
  //    margin-top: 1rem;
  //    background: transparent inline-svg("arrow-down", $global-sand-color) no-repeat;
  //    background-size: contain;
  //  }
  //  &:hover:after {
  //    background: transparent inline-svg("arrow-down", $global-darkblue-color) no-repeat;
  //    background-size: contain;
  //  }
  //
  //  &:before {
  //    content: "";
  //    display: none;
  //    width: 1.5rem;
  //    height: 1.5rem;
  //    margin-left: .75em;
  //    margin-right: .1em;
  //    margin-bottom: 1rem;
  //    background: transparent inline-svg("arrow-down", $global-sand-color) no-repeat;
  //    background-size: contain;
  //    transform: rotate(180deg);
  //  }
  //  &:hover:before {
  //    background: transparent inline-svg("arrow-down", $global-darkblue-color) no-repeat;
  //    background-size: contain;
  //  }
  //
  //  &.open {
  //    &:after {
  //      display: none;
  //    }
  //    &:before {
  //      display: block;
  //    }
  //  }
  //
  //}
  //.read-more:first-child:not(.open) + .link-readmore {
  //  margin-top: 0rem;
  //}


  [data-lightbox] {
    position: relative;
    display: block;

    .icon-expand {
      position: absolute;
      background: $global-color;
      color: white;
      right: 3%;
      top: 3%;
      padding: 5px;
      transition: all .2s ease-in;
    }

    &:hover {
      .icon-expand {
        background-color: $global-emphasis-color;
      }
    }
  }
}


.ce-chapter-start {
  @include breakpoint(max-width $breakpoint-small-max) {
    .uk-heading-large {
      font-size: 1.8rem;
    }
  }
}
