//
// Component:       Variables
// Description:     Defines common values which are used across all components
//
// ========================================================================

// Load deprecated components
// ========================================================================

$deprecated:                                    false !default;

// Breakpoints
// ========================================================================

// Phone Portrait:   Galaxy (360x640), iPhone 6 (375x667), iPhone 6+ (414x736)
// Phone Landscape:  Galaxy (640x360), iPhone 6 (667x375), iPhone 6+ (736x414)
// Tablet Portrait:  iPad (768x1024), Galaxy Tab (800x1280),
// Tablet Landscape: iPad (1024x768), iPad Pro (1024x1366),
// Desktop:          Galaxy Tab (1280x800), iPad Pro (1366x1024)

$breakpoint-small:                              640px !default;  // Phone landscape
$breakpoint-medium:                             860px !default;  // Tablet Landscape
$breakpoint-large:                              1080px !default; // Desktop
$breakpoint-xlarge:                             1600px !default; // Large Screens

$breakpoint-xsmall-max:                         ($breakpoint-small - 1) !default;
$breakpoint-small-max:                          ($breakpoint-medium - 1) !default;
$breakpoint-medium-max:                         ($breakpoint-large - 1) !default;
$breakpoint-large-max:                          ($breakpoint-xlarge - 1) !default;


// Global variables
// ========================================================================

//
// Typography
//

$global-font-family:                             'Avenir Next LT',Arial,Helvetica Neue,Helvetica,sans-serif !default;
$global-font-family-condensed:                    'Ubuntu Condensed', 'Arial Narrow',Helvetica Neue,Helvetica,sans-serif !default;
$global-font-size:                               18px !default;
$global-line-height:                             ( 28/18 ) !default;

$light: 300;
$normal: 500;
$semibold: 600;
$bold: 700;


$global-2xlarge-font-size:                       3.8461rem !default;   // 100px
$global-xlarge-font-size:                        2.777778rem !default; // 50px
$global-large-font-size:                        2rem !default; // 36px
$global-mediumlarge-font-size:                   1.666667rem !default; // 30px
$global-medium-font-size:                        1.444444rem !default; // 26px
$global-standard-font-size:                      1rem !default;        // 18px
$global-small-font-size:                         0.888889rem !default; // 16px
$global-tiny-font-size:                          0.833334rem !default; // 15px

//
// Colors
//

$global-color:                                   #0A1F5D !default;
$global-secondary-color:                         #004078;
$global-emphasis-color:                          #247ABC !default;
$global-muted-color:                             #74747B !default;
$global-dark-grey-color:                         #9094A7;

$global-color-dark:                               #000732;

$global-link-color:                              $global-emphasis-color !default;
$global-link-hover-color:                        black !default;

$global-inverse-color:                           #FFFFFF !default;


//
// Colors-Schemes
//


$color-A: #984CC8;
$color-B: #8959C0;
$color-C: #7057EA;
$color-D: #3B62ED;
$color-E: #516AC8;
$color-F: #007E9E;
$color-G: #008577;
$color-Y: #2B70C5;
$color-Z: #2B70C5;
$color-X: #247ABC;

$color-A-font: #9240C4;
$color-B-font: #814EBC;
$color-C-font: #6449E9;
$color-D-font: #2853EB;
$color-E-font: #4561C4;
$color-F-font: #00728F;
$color-G-font: #00756A;
$color-Y-font: #2869B8;
$color-Z-font: #2869B8;
$color-X-font: #206CA7;


$color-multiply: #A5B6C2;


$yellow: #FEAF12;
$green: #50E387;
$red: #FE124F;


  // INVERSE
  $inverse-global-color-mode:                      light !default;
  $inverse-global-color:                           $global-inverse-color !default;
  $inverse-global-emphasis-color:                  $global-emphasis-color !default;
  $inverse-global-muted-color:                     $global-inverse-color !default;
  $inverse-global-inverse-color:                   $global-color !default;
  $inverse-global-primary-background:              $global-inverse-color !default;
  $inverse-global-muted-background:                rgba($global-inverse-color, 0.1) !default;
  $inverse-global-border:                          rgba($global-inverse-color, 0.2) !default;

//
// Backgrounds
//
$global-background: #FFFFFF !default;

$global-muted-background:                        #EAEDF4 !default;
$global-primary-background:                      $global-color !default;
$global-secondary-background:                    $global-emphasis-color !default;
$global-tertiary-background:                      #021732;

$global-success-background:                      #32d296 !default;
$global-warning-background:                      #faa05a !default;
$global-danger-background:                       #E1000F !default;

//
// Borders
//

$global-border-width:                            2px !default;
$global-border:                                   #BCBCC3 !default;

//
// Box-Shadows
//

$global-small-box-shadow:                        0 2px 8px rgba(0,0,0,0.08) !default;
$global-medium-box-shadow:                       0 5px 15px rgba(0,0,0,0.08) !default;
$global-large-box-shadow:                        0 14px 25px rgba(0,0,0,0.16) !default;
$global-xlarge-box-shadow:                       0 28px 50px rgba(0,0,0,0.16) !default;

//
// Spacings
//

// Used in margin, section, list
$global-margin:                                  30px !default;
$global-small-margin:                            20px !default;
$global-medium-margin:                           50px !default;
$global-large-margin:                           70px !default;
$global-xlarge-margin:                           90px !default;

// Used in grid, column, container, align, card, padding
$global-gutter:                                  20px !default;
$global-small-gutter:                            10px !default;
$global-medium-gutter:                           30px !default;
$global-large-gutter:                            70px !default;

//
// Controls
//

$global-control-height:                          56px !default;
$global-control-small-height:                    50px !default;
$global-control-large-height:                    70px !default;

//
//
// Z-index
//

$global-z-index:                                 1000 !default;