// Name:            Card
// Description:     Component to create boxed content containers
//
// Component:       `uk-card`
//
// Sub-objects:     `uk-card-body`
//                  `uk-card-header`
//                  `uk-card-footer`
//                  `uk-card-media-*`
//                  `uk-card-title`
//                  `uk-card-badge`
//
// Modifiers:       `uk-card-hover`
//                  `uk-card-default`
//                  `uk-card-primary`
//                  `uk-card-secondary`
//                  `uk-card-small`
//                  `uk-card-large`
//
// Uses:            `uk-grid-stack`
//
// ========================================================================

 @mixin hook-card(){
  font-size: $global-small-font-size;


  transition: all .2s ease-in;

 }
 @mixin hook-card-body() {
 }
// @mixin hook-card-header(){}
// @mixin hook-card-footer(){}



// @mixin hook-card-media(){}
// @mixin hook-card-media-top(){}
// @mixin hook-card-media-bottom(){}
// @mixin hook-card-media-left(){}
// @mixin hook-card-media-right(){}


 @mixin hook-card-title(){
   font-size: 1.111111rem;
  line-height: (26 / 20);
 }
 @mixin hook-card-badge(){
 }
// @mixin hook-card-hover(){}
 @mixin hook-card-default(){
 }



 //@mixin hook-card-default-title(){}
 @mixin hook-card-default-hover(){

 }
 //@mixin hook-card-default-header(){}
// @mixin hook-card-default-footer(){}



@mixin hook-card-primary() {

 .uk-card-body {

 }

}
 @mixin hook-card-primary-title(){
  font-weight: $bold;
  @include breakpoint($breakpoint-small) {
   font-size: $global-medium-font-size;
  }
  .uk-light & {
   color: $global-secondary-color;
  }
 }
 @mixin hook-card-primary-hover(){
  .svg-icon {
   height: 80%;
  }
  .uk-light & {
   .svg-icon {
    fill: $inverse-global-color;
   }
  }
 }




 @mixin hook-card-secondary(){
  padding: 1em 1em;
  @include breakpoint($breakpoint-small) {
   padding: 2em 1em;
  }
 }
@mixin hook-card-secondary-title(){
 color: $global-inverse-color;
 @include breakpoint(max-width $breakpoint-xsmall-max) {
  font-size: $global-small-font-size;
 }
}
// @mixin hook-card-secondary-hover(){}


@mixin hook-card-misc(){


 .card--eval.inactive {

  opacity: .58;

  .slider-round-result {
   .rs-range-color {
    background-color: mix(white, $global-primary-background, 20%);
   }
   .rs-container::before {
    background-color: mix(white, $global-primary-background, 20%);
   }
   .rs-tooltip-text {
   display: none;
  }}
 }


 .card--icon-kachel {
  padding: 1em;

  @include breakpoint(max-width $breakpoint-xsmall-max) {
   .uk-button {
    width: 100%;
   }
  }

  @include breakpoint($breakpoint-small) {
   padding: 1.5em;
  }
  @include breakpoint($breakpoint-medium) {
   padding: 3em 2em;
  }

  .icon.icon--kachel {
   width: 3em;
   height: 3em;
   @include breakpoint($breakpoint-small) {
    width: 5em;
    height: 5em;
   }
   @include breakpoint($breakpoint-large) {
    width: 6em;
    height: 6em;
   }


   fill: $global-emphasis-color;
  }

  &.uk-card-primary .icon--kachel {
   fill: white;
  }
 }
 a.uk-card,.uk-card>a {
  display: block;
 }

 .card-detail-link {
  @include breakpoint(max-width $breakpoint-xsmall-max) {
   font-size: 14px;
  }
 }


 }

