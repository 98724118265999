/* ========================================================================
   Component: Width extended
 ========================================================================== */
/* Mixins
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
.uk-child-width-1-7 > * {
  width: 14.285%;
}
.uk-child-width-1-8 > * {
  width: 12.5%;
}
.uk-child-width-1-9 > * {
  width: 11.111%;
}
.uk-child-width-1-10 > * {
  width: 10%;
}
.uk-child-width-1-12 > * {
  width: 8.333%;
}
/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
  .uk-child-width-1-7\@s > * {
    width: 14.285%;
  }
  .uk-child-width-1-8\@s > * {
    width: 12.5%;
  }
  .uk-child-width-1-9\@s > * {
    width: 11.111%;
  }
  .uk-child-width-1-10\@s > * {
    width: 10%;
  }
  .uk-child-width-1-12\@s > * {
    width: 8.333%;
  }
}
/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
  .uk-child-width-1-7\@m > * {
    width: 14.285%;
  }
  .uk-child-width-1-8\@m > * {
    width: 12.5%;
  }
  .uk-child-width-1-9\@m > * {
    width: 11.111%;
  }
  .uk-child-width-1-10\@m > * {
    width: 10%;
  }
  .uk-child-width-1-12\@m > * {
    width: 8.333%;
  }
}
/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
  .uk-child-width-1-7\@l > * {
    width: 14.285%;
  }
  .uk-child-width-1-8\@l > * {
    width: 12.5%;
  }
  .uk-child-width-1-9\@l > * {
    width: 11.111%;
  }
  .uk-child-width-1-10\@l > * {
    width: 10%;
  }
  .uk-child-width-1-12\@l > * {
    width: 8.333%;
  }
}
/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {
  .uk-child-width-1-7\@xl > * {
    width: 14.285%;
  }
  .uk-child-width-1-8\@xl > * {
    width: 12.5%;
  }
  .uk-child-width-1-9\@xl > * {
    width: 11.111%;
  }
  .uk-child-width-1-10\@xl > * {
    width: 10%;
  }
  .uk-child-width-1-12\@xl > * {
    width: 8.333%;
  }
}
/* Single Widths
 ========================================================================== */
.uk-width-1-7 {
  width: 14.285%;
}
.uk-width-2-7 {
  width: 28.571%;
}
.uk-width-3-7 {
  width: 42.857%;
}
.uk-width-4-7 {
  width: 57.142%;
}
.uk-width-5-7 {
  width: 71.428%;
}
.uk-width-6-7 {
  width: 85.714%;
}
.uk-width-1-8 {
  width: 12.5%;
}
.uk-width-2-8 {
  width: 25%;
}
.uk-width-3-8 {
  width: 37.5%;
}
.uk-width-4-8 {
  width: 50%;
}
.uk-width-5-8 {
  width: 62.5%;
}
.uk-width-6-8 {
  width: 75%;
}
.uk-width-7-8 {
  width: 87.5%;
}
.uk-width-1-9 {
  width: 11.111%;
}
.uk-width-2-9 {
  width: 22.222%;
}
.uk-width-3-9 {
  width: 33.333%;
}
.uk-width-4-9 {
  width: 44.444%;
}
.uk-width-5-9 {
  width: 55.555%;
}
.uk-width-6-9 {
  width: 66.666%;
}
.uk-width-7-9 {
  width: 77.777%;
}
.uk-width-8-9 {
  width: 88.888%;
}
.uk-width-1-10 {
  width: 10%;
}
.uk-width-2-10 {
  width: 20%;
}
.uk-width-3-10 {
  width: 30%;
}
.uk-width-4-10 {
  width: 40%;
}
.uk-width-5-10 {
  width: 50%;
}
.uk-width-6-10 {
  width: 60%;
}
.uk-width-7-10 {
  width: 70%;
}
.uk-width-8-10 {
  width: 80%;
}
.uk-width-9-10 {
  width: 90%;
}
.uk-width-1-12 {
  width: 8.333%;
}
.uk-width-2-12 {
  width: 16.666%;
}
.uk-width-3-12 {
  width: 25%;
}
.uk-width-4-12 {
  width: 33.333%;
}
.uk-width-5-12 {
  width: 41.666%;
}
.uk-width-6-12 {
  width: 50%;
}
.uk-width-7-12 {
  width: 58.333%;
}
.uk-width-8-12 {
  width: 66.666%;
}
.uk-width-9-12 {
  width: 75%;
}
.uk-width-10-12 {
  width: 83.333%;
}
.uk-width-11-12 {
  width: 91.666%;
}
/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
  .uk-width-1-7\@s {
    width: 14.285%;
  }
  .uk-width-2-7\@s {
    width: 28.571%;
  }
  .uk-width-3-7\@s {
    width: 42.857%;
  }
  .uk-width-4-7\@s {
    width: 57.142%;
  }
  .uk-width-5-7\@s {
    width: 71.428%;
  }
  .uk-width-6-7\@s {
    width: 85.714%;
  }
  .uk-width-1-8\@s {
    width: 12.5%;
  }
  .uk-width-2-8\@s {
    width: 25%;
  }
  .uk-width-3-8\@s {
    width: 37.5%;
  }
  .uk-width-4-8\@s {
    width: 50%;
  }
  .uk-width-5-8\@s {
    width: 62.5%;
  }
  .uk-width-6-8\@s {
    width: 75%;
  }
  .uk-width-7-8\@s {
    width: 87.5%;
  }
  .uk-width-1-9\@s {
    width: 11.111%;
  }
  .uk-width-2-9\@s {
    width: 22.222%;
  }
  .uk-width-3-9\@s {
    width: 33.333%;
  }
  .uk-width-4-9\@s {
    width: 44.444%;
  }
  .uk-width-5-9\@s {
    width: 55.555%;
  }
  .uk-width-6-9\@s {
    width: 66.666%;
  }
  .uk-width-7-9\@s {
    width: 77.777%;
  }
  .uk-width-8-9\@s {
    width: 88.888%;
  }
  .uk-width-1-10\@s {
    width: 10%;
  }
  .uk-width-2-10\@s {
    width: 20%;
  }
  .uk-width-3-10\@s {
    width: 30%;
  }
  .uk-width-4-10\@s {
    width: 40%;
  }
  .uk-width-5-10\@s {
    width: 50%;
  }
  .uk-width-6-10\@s {
    width: 60%;
  }
  .uk-width-7-10\@s {
    width: 70%;
  }
  .uk-width-8-10\@s {
    width: 80%;
  }
  .uk-width-9-10\@s {
    width: 90%;
  }
  .uk-width-1-12\@s {
    width: 8.333%;
  }
  .uk-width-2-12\@s {
    width: 16.666%;
  }
  .uk-width-3-12\@s {
    width: 25%;
  }
  .uk-width-4-12\@s {
    width: 33.333%;
  }
  .uk-width-5-12\@s {
    width: 41.666%;
  }
  .uk-width-6-12\@s {
    width: 50%;
  }
  .uk-width-7-12\@s {
    width: 58.333%;
  }
  .uk-width-8-12\@s {
    width: 66.666%;
  }
  .uk-width-9-12\@s {
    width: 75%;
  }
  .uk-width-10-12\@s {
    width: 83.333%;
  }
  .uk-width-11-12\@s {
    width: 91.666%;
  }
}
/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
  .uk-width-1-7\@m {
    width: 14.285%;
  }
  .uk-width-2-7\@m {
    width: 28.571%;
  }
  .uk-width-3-7\@m {
    width: 42.857%;
  }
  .uk-width-4-7\@m {
    width: 57.142%;
  }
  .uk-width-5-7\@m {
    width: 71.428%;
  }
  .uk-width-6-7\@m {
    width: 85.714%;
  }
  .uk-width-1-8\@m {
    width: 12.5%;
  }
  .uk-width-2-8\@m {
    width: 25%;
  }
  .uk-width-3-8\@m {
    width: 37.5%;
  }
  .uk-width-4-8\@m {
    width: 50%;
  }
  .uk-width-5-8\@m {
    width: 62.5%;
  }
  .uk-width-6-8\@m {
    width: 75%;
  }
  .uk-width-7-8\@m {
    width: 87.5%;
  }
  .uk-width-1-9\@m {
    width: 11.111%;
  }
  .uk-width-2-9\@m {
    width: 22.222%;
  }
  .uk-width-3-9\@m {
    width: 33.333%;
  }
  .uk-width-4-9\@m {
    width: 44.444%;
  }
  .uk-width-5-9\@m {
    width: 55.555%;
  }
  .uk-width-6-9\@m {
    width: 66.666%;
  }
  .uk-width-7-9\@m {
    width: 77.777%;
  }
  .uk-width-8-9\@m {
    width: 88.888%;
  }
  .uk-width-1-10\@m {
    width: 10%;
  }
  .uk-width-2-10\@m {
    width: 20%;
  }
  .uk-width-3-10\@m {
    width: 30%;
  }
  .uk-width-4-10\@m {
    width: 40%;
  }
  .uk-width-5-10\@m {
    width: 50%;
  }
  .uk-width-6-10\@m {
    width: 60%;
  }
  .uk-width-7-10\@m {
    width: 70%;
  }
  .uk-width-8-10\@m {
    width: 80%;
  }
  .uk-width-9-10\@m {
    width: 90%;
  }
  .uk-width-1-12\@m {
    width: 8.333%;
  }
  .uk-width-2-12\@m {
    width: 16.666%;
  }
  .uk-width-3-12\@m {
    width: 25%;
  }
  .uk-width-4-12\@m {
    width: 33.333%;
  }
  .uk-width-5-12\@m {
    width: 41.666%;
  }
  .uk-width-6-12\@m {
    width: 50%;
  }
  .uk-width-7-12\@m {
    width: 58.333%;
  }
  .uk-width-8-12\@m {
    width: 66.666%;
  }
  .uk-width-9-12\@m {
    width: 75%;
  }
  .uk-width-10-12\@m {
    width: 83.333%;
  }
  .uk-width-11-12\@m {
    width: 91.666%;
  }
}
/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
  .uk-width-1-7\@l {
    width: 14.285%;
  }
  .uk-width-2-7\@l {
    width: 28.571%;
  }
  .uk-width-3-7\@l {
    width: 42.857%;
  }
  .uk-width-4-7\@l {
    width: 57.142%;
  }
  .uk-width-5-7\@l {
    width: 71.428%;
  }
  .uk-width-6-7\@l {
    width: 85.714%;
  }
  .uk-width-1-8\@l {
    width: 12.5%;
  }
  .uk-width-2-8\@l {
    width: 25%;
  }
  .uk-width-3-8\@l {
    width: 37.5%;
  }
  .uk-width-4-8\@l {
    width: 50%;
  }
  .uk-width-5-8\@l {
    width: 62.5%;
  }
  .uk-width-6-8\@l {
    width: 75%;
  }
  .uk-width-7-8\@l {
    width: 87.5%;
  }
  .uk-width-1-9\@l {
    width: 11.111%;
  }
  .uk-width-2-9\@l {
    width: 22.222%;
  }
  .uk-width-3-9\@l {
    width: 33.333%;
  }
  .uk-width-4-9\@l {
    width: 44.444%;
  }
  .uk-width-5-9\@l {
    width: 55.555%;
  }
  .uk-width-6-9\@l {
    width: 66.666%;
  }
  .uk-width-7-9\@l {
    width: 77.777%;
  }
  .uk-width-8-9\@l {
    width: 88.888%;
  }
  .uk-width-1-10\@l {
    width: 10%;
  }
  .uk-width-2-10\@l {
    width: 20%;
  }
  .uk-width-3-10\@l {
    width: 30%;
  }
  .uk-width-4-10\@l {
    width: 40%;
  }
  .uk-width-5-10\@l {
    width: 50%;
  }
  .uk-width-6-10\@l {
    width: 60%;
  }
  .uk-width-7-10\@l {
    width: 70%;
  }
  .uk-width-8-10\@l {
    width: 80%;
  }
  .uk-width-9-10\@l {
    width: 90%;
  }
  .uk-width-1-12\@l {
    width: 8.333%;
  }
  .uk-width-2-12\@l {
    width: 16.666%;
  }
  .uk-width-3-12\@l {
    width: 25%;
  }
  .uk-width-4-12\@l {
    width: 33.333%;
  }
  .uk-width-5-12\@l {
    width: 41.666%;
  }
  .uk-width-6-12\@l {
    width: 50%;
  }
  .uk-width-7-12\@l {
    width: 58.333%;
  }
  .uk-width-8-12\@l {
    width: 66.666%;
  }
  .uk-width-9-12\@l {
    width: 75%;
  }
  .uk-width-10-12\@l {
    width: 83.333%;
  }
  .uk-width-11-12\@l {
    width: 91.666%;
  }
}
/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {
  .uk-width-1-7\@xl {
    width: 14.285%;
  }
  .uk-width-2-7\@xl {
    width: 28.571%;
  }
  .uk-width-3-7\@xl {
    width: 42.857%;
  }
  .uk-width-4-7\@xl {
    width: 57.142%;
  }
  .uk-width-5-7\@xl {
    width: 71.428%;
  }
  .uk-width-6-7\@xl {
    width: 85.714%;
  }
  .uk-width-1-8\@xl {
    width: 12.5%;
  }
  .uk-width-2-8\@xl {
    width: 25%;
  }
  .uk-width-3-8\@xl {
    width: 37.5%;
  }
  .uk-width-4-8\@xl {
    width: 50%;
  }
  .uk-width-5-8\@xl {
    width: 62.5%;
  }
  .uk-width-6-8\@xl {
    width: 75%;
  }
  .uk-width-7-8\@xl {
    width: 87.5%;
  }
  .uk-width-1-9\@xl {
    width: 11.111%;
  }
  .uk-width-2-9\@xl {
    width: 22.222%;
  }
  .uk-width-3-9\@xl {
    width: 33.333%;
  }
  .uk-width-4-9\@xl {
    width: 44.444%;
  }
  .uk-width-5-9\@xl {
    width: 55.555%;
  }
  .uk-width-6-9\@xl {
    width: 66.666%;
  }
  .uk-width-7-9\@xl {
    width: 77.777%;
  }
  .uk-width-8-9\@xl {
    width: 88.888%;
  }
  .uk-width-1-10\@xl {
    width: 10%;
  }
  .uk-width-2-10\@xl {
    width: 20%;
  }
  .uk-width-3-10\@xl {
    width: 30%;
  }
  .uk-width-4-10\@xl {
    width: 40%;
  }
  .uk-width-5-10\@xl {
    width: 50%;
  }
  .uk-width-6-10\@xl {
    width: 60%;
  }
  .uk-width-7-10\@xl {
    width: 70%;
  }
  .uk-width-8-10\@xl {
    width: 80%;
  }
  .uk-width-9-10\@xl {
    width: 90%;
  }
  .uk-width-1-12\@xl {
    width: 8.333%;
  }
  .uk-width-2-12\@xl {
    width: 16.666%;
  }
  .uk-width-3-12\@xl {
    width: 25%;
  }
  .uk-width-4-12\@xl {
    width: 33.333%;
  }
  .uk-width-5-12\@xl {
    width: 41.666%;
  }
  .uk-width-6-12\@xl {
    width: 50%;
  }
  .uk-width-7-12\@xl {
    width: 58.333%;
  }
  .uk-width-8-12\@xl {
    width: 66.666%;
  }
  .uk-width-9-12\@xl {
    width: 75%;
  }
  .uk-width-10-12\@xl {
    width: 83.333%;
  }
  .uk-width-11-12\@xl {
    width: 91.666%;
  }
}
