// Name:            Section
// Description:     Component to create horizontal layout section
//
// Component:       `uk-section`
//
// Modifiers:       `uk-section-xsmall`
//                  `uk-section-small`
//                  `uk-section-large`
//                  `uk-section-xlarge`
//                  `uk-section-default`
//                  `uk-section-muted`
//                  `uk-section-primary`
//                  `uk-section-secondary`
//                  `uk-section-overlap`
//
// States:          `uk-preserve-color`
//
// ========================================================================


// Hooks
// ========================================================================


// @mixin hook-section(){}
// @mixin hook-section-default(){}
// @mixin hook-section-muted(){}
// @mixin hook-section-secondary(){}
// @mixin hook-section-primary(){}
// @mixin hook-section-overlap(){}
 @mixin hook-section-misc(){
   .uk-section-large:not([class*=uk-background-]) {
     @include breakpoint(max-width $breakpoint-small-max) {
       padding-top: 40px;
     }
     @include breakpoint(max-width $breakpoint-xsmall-max) {
       padding-top: 20px;
     }
   }
 }