// Name:            Link
// Description:     Styles for links
//
// Component:       `uk-link-muted`
//                  `uk-link-text`
//                  `uk-link-heading`
//                  `uk-link-reset`
//
// ========================================================================


// Variables
// ========================================================================

$link-muted-color:                               $global-muted-color !default;
$link-muted-hover-color:                         $global-color !default;

$link-text-hover-color:                          $global-emphasis-color !default;

$link-heading-hover-color:                       $global-primary-background !default;
$link-heading-hover-text-decoration:             none !default;


// Inverse
// ========================================================================


$inverse-link-muted-color:                         $inverse-global-muted-color !default;
$inverse-link-muted-hover-color:                   $inverse-global-color !default;
$inverse-link-text-hover-color:                    $global-emphasis-color !default;
$inverse-link-heading-hover-color:                 $inverse-global-primary-background !default;
