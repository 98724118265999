.irs-grid-pol {

        background: $global-emphasis-color;
        height: 14px;
  width: 2px;
  top: -22px;


  &:before {
    content: "";
    position: absolute;
    top: -22px;
    display: block;
    height: 18px;
    width: 18px;
    border: 2px solid $global-emphasis-color;
    background-color: #FFFFFF;
    margin-left: -11px;
    border-radius: 100%;
    pointer-events: none;
    z-index: 1;

  }

        &.small {
          display: none;
        }
}

.irs {
  font-family: $global-font-family;
}
.irs--big {
  .irs-grid {
    height: 40px;
  }
  .irs-line {
    top: 28px;
    height: 22px;
    border: none;
    background: $global-muted-background;
    margin-right: 3px;
    margin-left: 3px;
    cursor: pointer;

  }
  .irs-bar {
    top: 28px;
    height: 22px;
    border: none;
    background: $global-emphasis-color;
    box-shadow: none;
    margin-left: 3px;
    transition: all .2s ease-in;
    cursor: pointer;

    .scheme-a & {background: $color-A;}
    .scheme-b & {background: $color-B;}
    .scheme-c & {background: $color-C;}
    .scheme-d & {background: $color-D;}
    .scheme-e & {background: $color-E;}
    .scheme-f & {background: $color-F;}
    .scheme-g & {background: $color-G;}
    .scheme-y & {background: $color-Y;}
    .scheme-z & {background: $color-Z;}
    .scheme-x & {background: $color-X;}
  }

  .irs-handle {
    &, &:hover, &.state_hover {
    width: 28px;
    height: 28px;
    background:  blend-multiply($global-emphasis-color, $color-multiply);
    border: none;
    box-shadow: none;
      transition: all .2s ease-in;
      z-index: 2;
    }

    .scheme-a & {background: blend-multiply($color-A, $color-multiply);}
    .scheme-b & {background: blend-multiply($color-B, $color-multiply);}
    .scheme-c & {background: blend-multiply($color-C, $color-multiply);}
    .scheme-d & {background: blend-multiply($color-D, $color-multiply);}
    .scheme-e & {background: blend-multiply($color-E, $color-multiply);}
    .scheme-f & {background: blend-multiply($color-F, $color-multiply);}
    .scheme-g & {background: blend-multiply($color-G, $color-multiply);}
    .scheme-y & {background: blend-multiply($color-Y, $color-multiply);}
    .scheme-z & {background: blend-multiply($color-Z, $color-multiply);}
    .scheme-x & {background: blend-multiply($color-X, $color-multiply);}
  }

  &.irs-with-grid {
    height: 112px;
  }

  .irs-grid-text {
    color: #0A1F5D;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    min-height: 44px;
    max-width: 100px;
    white-space: normal;
  }
}

.range-slider-bottom-margin {
  margin-bottom: -16px;
}