// Name:            Base
// Description:     Default values for HTML elements
//
// Component:       `uk-link`
//                  `uk-h1`, `uk-h2`, `uk-h3`, `uk-h4`, `uk-h5`, `uk-h6`
//                  `uk-hr`
//
// ========================================================================



@mixin hook-base-body(){
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;



  @include breakpoint(max-width $breakpoint-small-max) {
    & > * {
      font-size: 16px;
      line-height: (28 / 18);
    }
  }

}
 @mixin hook-base-link(){
   transition: all .2s ease-in;
 }
 //@mixin hook-base-link-hover(){}
 //@mixin hook-base-heading(){ }
 @mixin hook-base-h1(){
   & + & {
     margin-top: 0;
   }
   @include breakpoint(max-width $breakpoint-small-max) {
     font-size: $global-mediumlarge-font-size;
   }
 }
 @mixin hook-base-h2(){
   margin-bottom: 1em;

   &:not(:first-child) {
     margin-top:2em;
   }
   @include breakpoint(max-width $breakpoint-small-max) {
     font-size: $global-medium-font-size;
     line-height: 1.3;
   }
 }
 @mixin hook-base-h3(){
   margin-bottom: 0;

   &:not(:first-child) {
     margin-top: 2.5em;
   }

   //.copytext & + * {
   //  margin-top: 0;
   //}

   .copytext & + ul {
     margin-top: .3em;
   }

   .copytext & + p {
     margin-top: .3em;
   }

   @include breakpoint(max-width $breakpoint-small-max) {
     font-size: 1.222222rem;
   }

 }
 @mixin hook-base-h4(){

   & + * {
     margin-top: 0;
   }

   @include breakpoint(max-width $breakpoint-xsmall-max) {
     font-size: 1.15rem;
   }

 }
 @mixin hook-base-h5(){

   @include breakpoint(max-width $breakpoint-medium) {
     font-size: $global-small-font-size;
   }
 }
 @mixin hook-base-h6(){
   font-weight: $bold;
   margin-bottom: 0;
   & + * {
     margin-top: 0;
   }
 }

 @mixin hook-base-hr(){
 }

@mixin hook-base-blockquote(){
    padding: 2em 12%;
    border: 12px solid $global-emphasis-color;
    border-bottom: none;
    position: relative;
   margin-bottom: 70px;

  @include breakpoint($breakpoint-small) {
    font-size: $global-medium-font-size;
    padding: 2em 16%;
  }

    @include breakpoint($breakpoint-large) {
      font-size: $global-large-font-size;
    }

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 70px;
    left: -12px;
    right: -12px;
    top: 100%;
    background: transparent url('../images/line_quote.svg') no-repeat center;
    background-size: cover;
  }


}
@mixin hook-base-blockquote-footer(){
  color: $global-emphasis-color;
  font-weight: bold;
  letter-spacing: 0.82px;
  text-transform: uppercase;

  @include breakpoint($breakpoint-medium) {
    font-size: $global-tiny-font-size;
  }
}
// @mixin hook-base-pre(){}
 @mixin hook-base-misc(){

  strong {
    font-weight: $bold;
    //font-weight: $semibold;
  }



   p:last-child {
     margin-bottom: 0;
   }
   figure {
     &:last-child {
       margin-bottom: 0;
     }

     figcaption {
       color: $global-muted-color;
       font-size: $global-small-font-size;
       font-style: italic;
       letter-spacing: 0.5px;
       line-height: 1.5;
       padding: $global-small-gutter 0 0;
     }
   }
   .pretitle {
     font-weight: $semibold;
     color: $global-color;
     margin-bottom: 10px;
     letter-spacing: 1.5px;
     text-transform: uppercase;
     font-size: .72222222rem;
     @include breakpoint($breakpoint-small) {
     font-size: .77777778rem;
     }

     .scheme-a & {color: $color-A-font;}
     .scheme-b & {color: $color-B-font;}
     .scheme3-c & {color: $color-C-font;}
     .scheme-d & {color: $color-D-font;}
     .scheme-e & {color: $color-E-font;}
     .scheme-f & {color: $color-F-font;}
     .scheme-g & {color: $color-G-font;}
     .scheme-x & {color: $color-X-font}
     .scheme-y & {color: $color-Y-font}
     .scheme-z & {color: $color-X-font}

     .uk-light & {
       color: $global-inverse-color;
     }
   }

   .map-responsive{
     overflow:hidden;
     padding-bottom:56.25%;
     position:relative;
     height:0;
   }
   .map-responsive iframe{
     left:0;
     top:0;
     height:100%;
     width:100%;
     position:absolute;
   }


  .uk-headline-medium {
    font-size: $global-medium-font-size;
    @include breakpoint($breakpoint-medium) {
      font-size: $global-xlarge-font-size;

    }
  }


 }


// Inverse
// ========================================================================

// @mixin hook-inverse-base-link(){}
// @mixin hook-inverse-base-link-hover(){}
// @mixin hook-inverse-base-code(){}
// @mixin hook-inverse-base-heading(){}
// @mixin hook-inverse-base-h1(){}
// @mixin hook-inverse-base-h2(){}
// @mixin hook-inverse-base-h3(){}
// @mixin hook-inverse-base-h4(){}
// @mixin hook-inverse-base-h5(){}
// @mixin hook-inverse-base-h6(){}
// @mixin hook-inverse-base-blockquote(){}
// @mixin hook-inverse-base-blockquote-footer(){}
// @mixin hook-inverse-base-hr(){}
