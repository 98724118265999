// Name:            Progress
// Description:     Component to create progress bars
//
// Component:       `uk-progress`
//
// ========================================================================



// Hooks
// ========================================================================

 @mixin hook-progress(){
   .trenner.scheme-a & {background: $color-A;}
   .trenner.scheme-b & {background: $color-B;}
   .trenner.scheme-c & {background: $color-C;}
   .trenner.scheme-d & {background: $color-D;}
   .trenner.scheme-e & {background: $color-E;}
   .trenner.scheme-f & {background: $color-F;}
   .trenner.scheme-g & {background: $color-G;}
   .trenner.scheme-y & {background: $color-Y;}
   .trenner.scheme-z & {background: $color-Z;}
   .trenner.scheme-x & {background: $color-X;}
 }
 @mixin hook-progress-bar(){
     .scheme-a & {background: $color-A;}
     .scheme-b & {background: $color-B;}
     .scheme-c & {background: $color-C;}
     .scheme-d & {background: $color-D;}
     .scheme-e & {background: $color-E;}
     .scheme-f & {background: $color-F;}
     .scheme-g & {background: $color-G;}
     .scheme-y & {background: $color-Y;}
     .scheme-z & {background: $color-Z;}
     .scheme-x & {background: $color-X;}


   .trenner & {background: white!important;}


 }
 @mixin hook-progress-misc(){
     .progress-mobile-text {
       font-weight: $bold;
       font-size: .777777778rem;
       padding: 10px 0;

       .trenner & {
         color: white;
       }
     }

   .progress-desktop {
      display: flex;
     margin-top: 1em;

     .bereich {
       display: flex;
       flex-direction: row;
       margin-left: 8px;
       transition: ease-in all .2s;

       span {
         display: flex;
         margin-left: .2vw;
         width: 1.1vw;
         height: 2px;
         background: rgba(#FFFFFF, .3);
         position: relative;
         transition: ease-in all .2s;
       }

       &[data-bereich=a] span.active{background: $color-A;}
       &[data-bereich=b] span.active{background: $color-B;}
       &[data-bereich=c] span.active{background: $color-C;}
       &[data-bereich=d] span.active{background: $color-D;}
       &[data-bereich=e] span.active{background: $color-E;}
       &[data-bereich=f] span.active{background: $color-F;}
       &[data-bereich=g] span.active{background: $color-G;}
       &[data-bereich=y] span.active{background: $color-Y;}
       &[data-bereich=z] span.active{background: $color-Z;}
       &[data-bereich=x] span.active{background: $color-X;}

       &.inactive {
         span {
           width: 0;
         }
         width: 0;
         margin-left: 0;
       }
       span.current:before {
         content: attr(data-no) ' / ' attr(data-sum);
         position: absolute;
         color: $global-secondary-color;
         font-size: 1rem;
         font-weight: bold;
         bottom: calc(100% + 11px);
         display: block;
         height: 28px;
         min-width: 40px;
         width: 80px;
         text-align: center;
         background: white;
         left: 50%;
         transform: translateX(-50%);
       }
       span.current:after {
         content: "";
         position: absolute;
         left: 50%;
         z-index: 1;
         display: block;
         bottom: calc(100% + 3px);
         width: 0;
         height: 0;
         border-style: solid;
         border-width: 8px 8px 0 8px;
         border-color: #FFFFFF transparent  transparent  transparent;
         transform: translateX(-50%);
       }


       @include breakpoint($breakpoint-large) {
         span {
           width: 1.1vw;
           height: 4px;
           margin-left: 3px;
         }
       }
       @include breakpoint($breakpoint-xlarge) {
         span {
           max-width: 18px;
           height: 4px;
           margin-left: 2px;
         }
       }

     }
   }

 }
