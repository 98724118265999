// Name:            Form
// Description:     Styles for forms
//
// Component:       `uk-form-*`
//                  `uk-input`
//                  `uk-select`
//                  `uk-textarea`
//                  `uk-radio`
//                  `uk-checkbox`
//                  `uk-legend`
//                  `uk-fieldset`
//
// Sub-objects:     `uk-form-custom`
//                  `uk-form-stacked`
//                  `uk-form-horizontal`
//                  `uk-form-label`
//                  `uk-form-controls`
//                  `uk-form-icon`
//                  `uk-form-icon-flip`
//
// Modifiers:       `uk-form-small`
//                  `uk-form-large`
//                  `uk-form-danger`
//                  `uk-form-success`
//                  `uk-form-blank`
//                  `uk-form-width-xsmall`
//                  `uk-form-width-small`
//                  `uk-form-width-medium`
//                  `uk-form-width-large`
//                  `uk-form-controls-text`
//
// ========================================================================


// Variables
// ========================================================================

$form-height:                                    60px !default;
$form-line-height:                               $form-height !default;
$form-padding-horizontal:                        15px !default;
$form-padding-vertical:                          15px !default;

$form-background:                                $global-muted-background !default;
$form-color:                                     $global-color !default;

$form-focus-background:                          $global-inverse-color !default;
$form-focus-color:                               $global-secondary-color !default;

$form-disabled-background:                       $global-muted-background !default;
$form-disabled-color:                            $global-muted-color !default;

$form-placeholder-color:                         rgba($form-color,.6) !default;

$form-small-height:                              $global-control-small-height !default;
$form-small-padding-horizontal:                  8px !default;
$form-small-line-height:                         $form-small-height !default;
$form-small-font-size:                           $global-small-font-size !default;

$form-large-height:                              $global-control-large-height !default;
$form-large-padding-horizontal:                  12px !default;
$form-large-line-height:                         $form-large-height !default;
$form-large-font-size:                           $global-medium-font-size !default;

$form-danger-color:                              $global-danger-background !default;
$form-success-color:                             $global-success-background !default;

$form-width-xsmall:                              50px !default;
$form-width-small:                               130px !default;
$form-width-medium:                              200px !default;
$form-width-large:                               500px !default;

$form-select-padding-right:                      20px !default;
$form-select-icon-color:                         $global-color !default;
$form-select-option-color:                       #444 !default;
$form-select-disabled-icon-color:                $global-muted-color !default;

$form-datalist-padding-right:                    20px !default;
$form-datalist-icon-color:                       $global-color !default;

$form-radio-size:                                20px !default;
$form-radio-margin-top:                          -5px !default;
$form-radio-background:                          white !default;

$form-radio-checked-background:                  $global-secondary-color !default;
$form-radio-checked-icon-color:                  $global-inverse-color !default;

$form-radio-checked-focus-background:            $global-secondary-color !default;

$form-radio-disabled-background:                 $global-muted-background !default;
$form-radio-disabled-icon-color:                 $global-muted-color !default;

$form-legend-font-size:                          $global-mediumlarge-font-size !default;
$form-legend-line-height:                        (41 / 30) !default;

$form-stacked-margin-bottom:                     $global-small-margin !default;

$form-horizontal-label-width:                    200px !default;
$form-horizontal-label-margin-top:               7px !default;
$form-horizontal-controls-margin-left:           215px !default;
$form-horizontal-controls-text-padding-top:      7px !default;

$form-icon-width:                                $form-height !default;
$form-icon-color:                                $global-muted-color !default;
$form-icon-hover-color:                          $global-color !default;

$internal-form-select-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-datalist-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
//$internal-form-radio-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-radio-image: "data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath d='M17 3.586L18.414 5 7 16.414 1.586 11 3 9.586l4 3.999 10-9.999z' fill='#000' fill-rule='nonzero'/%3E%3C/svg%3E%0A" !default;
$internal-form-radio-no-image: "data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' stroke-miterlimit='2'%3E%3Cg fill='#000' fill-rule='nonzero'%3E%3Cpath d='M16 2.586L17.414 4 4 17.414 2.586 16 16 2.586z'/%3E%3Cpath d='M2.586 4L4 2.586 17.414 16 16 17.414 2.586 4z'/%3E%3C/g%3E%3C/svg%3E%0A" !default;
$internal-form-radio-yes-image: "data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath d='M17 3.586L18.414 5 7 16.414 1.586 11 3 9.586l4 3.999 10-9.999z' fill='#000' fill-rule='nonzero'/%3E%3C/svg%3E%0A" !default;
//$internal-form-radio-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-form-checkbox-image: "data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath fill='#000' d='M17 3.586L18.414 5 7 16.414 1.586 11 3 9.586l4 3.999 10-9.999z' fill-rule='nonzero'/%3E%3C/svg%3E%0A" !default;
$internal-form-checkbox-indeterminate-image: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIwIDIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MjsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSw0LDQpIj4KICAgICAgICA8ZyBpZD0iQW50d29ydC1uZWluIj4KICAgICAgICAgICAgPGcgaWQ9InMtcmVtb3ZlIj4KICAgICAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGlkPSJQYXRoIiBkPSJNMTIsLTEuNDE0TDEzLjQxNCwwTDAsMTMuNDE0TC0xLjQxNCwxMkwxMiwtMS40MTRaIiBzdHlsZT0iZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggaWQ9IlBhdGgxIiBzZXJpZjppZD0iUGF0aCIgZD0iTS0xLjQxNCwwTDAsLTEuNDE0TDEzLjQxNCwxMkwxMiwxMy40MTRMLTEuNDE0LDBaIiBzdHlsZT0iZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=" !default;

// Inverse
// ========================================================================

$inverse-form-background:                       white !default;
$inverse-form-color:                            $inverse-global-color !default;
$inverse-form-focus-background:                 $inverse-global-muted-background !default;
$inverse-form-focus-color:                      $inverse-global-color !default;
$inverse-form-placeholder-color:                $inverse-global-muted-color !default;

$inverse-form-select-icon-color:                $inverse-global-color !default;

$inverse-form-datalist-icon-color:              $inverse-global-color !default;

$inverse-form-radio-background:                 darken($inverse-global-muted-background, 5%) !default;

$inverse-form-radio-checked-background:         $inverse-global-primary-background !default;
$inverse-form-radio-checked-icon-color:         $inverse-global-inverse-color !default;

$inverse-form-radio-checked-focus-background:   darken($inverse-global-primary-background, 10%) !default;

$inverse-form-icon-color:                       $inverse-global-muted-color !default;
$inverse-form-icon-hover-color:                 $inverse-global-color !default;
