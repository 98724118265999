// Name:            Form
// Description:     Styles for forms
//
// Component:       `uk-form-*`
//                  `uk-input`
//                  `uk-select`
//                  `uk-textarea`
//                  `uk-radio`
//                  `uk-checkbox`
//                  `uk-legend`
//                  `uk-fieldset`
//
// Sub-objects:     `uk-form-custom`
//                  `uk-form-stacked`
//                  `uk-form-horizontal`
//                  `uk-form-label`
//                  `uk-form-controls`
//                  `uk-form-icon`
//                  `uk-form-icon-flip`
//
// Modifiers:       `uk-form-small`
//                  `uk-form-large`
//                  `uk-form-danger`
//                  `uk-form-success`
//                  `uk-form-blank`
//                  `uk-form-width-xsmall`
//                  `uk-form-width-small`
//                  `uk-form-width-medium`
//                  `uk-form-width-large`
//                  `uk-form-controls-text`
//
// ========================================================================



// Hooks
// ========================================================================

@mixin hook-form(){
	transition: all .2s ease-in;
	border: 2px solid $form-background;
	&.uk-textarea {
		font-weight: normal;
	}
}
@mixin hook-form-single-line(){
	@include breakpoint(max-width $breakpoint-xsmall-max) {
		height: 50px;
	}
}
// @mixin hook-form-multi-line(){}
//@mixin hook-form-focus(){}
// @mixin hook-form-disabled(){}
 @mixin hook-form-danger(){
	 .uk-form-label {
		 color: $global-danger-background;
	 }
	.uk-input {
	 border: 3px solid $global-danger-background;
		background: transparent;


	}

 }
// @mixin hook-form-success(){}
// @mixin hook-form-blank(){}
// @mixin hook-form-blank-focus(){}
 @mixin hook-form-radio(){
	 transition: all .2s ease-in;
	 border: 2px solid $global-color;
	 background-size: 75%;

	 @include breakpoint($breakpoint-small) {
		 width: 25px;
		 height: 25px;
		 margin-top: -6px;
	 }

	 &:focus, &:checked {
		 .scheme-a & {border-color: $color-A;}
		 .scheme-b & {border-color: $color-B;}
		 .scheme-c & {border-color: $color-C;}
		 .scheme-d & {border-color: $color-D;}
		 .scheme-e & {border-color: $color-E;}
		 .scheme-f & {border-color: $color-F;}
		 .scheme-g & {border-color: $color-G;}
		 .scheme-y & {border-color: $color-Y;}
		 .scheme-z & {border-color: $color-Z;}
		 .scheme-x & {border-color: $color-X;}
	 }



 }
// @mixin hook-form-radio-focus(){}
@mixin hook-form-radio-checked(){

	&.check-no {
		@include svg-fill($internal-form-radio-no-image, "#000", $form-radio-checked-icon-color)
	}
	.scheme-a & {background-color: $color-A;}
	.scheme-b & {background-color: $color-B;}
	.scheme-c & {background-color: $color-C;}
	.scheme-d & {background-color: $color-D;}
	.scheme-e & {background-color: $color-E;}
	.scheme-f & {background-color: $color-F;}
	.scheme-g & {background-color: $color-G;}
	.scheme-y & {background-color: $color-Y;}
	.scheme-z & {background-color: $color-Z;}
	.scheme-x & {background-color: $color-X;}
}
 @mixin hook-form-radio-checked-focus(){
	 .scheme-a & {background-color: $color-A;}
	 .scheme-b & {background-color: $color-B;}
	 .scheme-c & {background-color: $color-C;}
	 .scheme-d & {background-color: $color-D;}
	 .scheme-e & {background-color: $color-E;}
	 .scheme-f & {background-color: $color-F;}
	 .scheme-g & {background-color: $color-G;}
	 .scheme-y & {background-color: $color-Y;}
	 .scheme-z & {background-color: $color-Z;}
	 .scheme-x & {background-color: $color-X;}
 }
 @mixin hook-form-radio-disabled(){
	 cursor: auto;

	 [class*="scheme-"] &

		 {
	 	border-color: #CFD4E0!important;
	 	background-color: #CFD4E0;

	 }

	 [class*="scheme-"] input:checked {
		 $internal-form-radio-image: "data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath d='M17 3.586L18.414 5 7 16.414 1.586 11 3 9.586l4 3.999 10-9.999z' fill='#000' fill-rule='nonzero'/%3E%3C/svg%3E%0A" !default;
		 @include svg-fill($internal-form-radio-image, "#000", $color-X);
	 }

 }

// @mixin hook-form-legend(){}
// @mixin hook-form-label(){}
//@mixin hook-form-stacked-label(){}
// @mixin hook-form-horizontal-label(){}
@mixin hook-form-misc(){

	.form-checkbox {
		cursor: pointer;
		display: inline-block;
		position: relative;
		padding-left: 1.75em;
		font-size: $global-small-font-size;
		font-weight: $bold;
		line-height: (22 /16);

		@include breakpoint($breakpoint-small) {
			padding-left: 2.75em;
		}

		&.disabled  {
			cursor: auto;
		}
		input {
			position: absolute;
			left: 0;
			top: .35em;
			color: white;
			border-radius: 100%;
			border-color: $global-emphasis-color;

			&:checked {
				.scheme-a & {border-color: $color-A;}
				.scheme-b & {border-color: $color-B;}
				.scheme-c & {border-color: $color-C;}
				.scheme-d & {border-color: $color-D;}
				.scheme-e & {border-color: $color-E;}
				.scheme-f & {border-color: $color-F;}
				.scheme-g & {border-color: $color-G;}
				.scheme-y & {border-color: $color-Y;}
				.scheme-z & {border-color: $color-Z;}
				.scheme-x & {border-color: $color-X;}
			}
			&:disabled:checked {
				.scheme-a & {@include svg-fill($internal-form-radio-image, "#000", $color-A);}
				.scheme-b & {@include svg-fill($internal-form-radio-image, "#000", $color-B);}
				.scheme-c & {@include svg-fill($internal-form-radio-image, "#000", $color-C);}
				.scheme-d & {@include svg-fill($internal-form-radio-image, "#000", $color-D);}
				.scheme-e & {@include svg-fill($internal-form-radio-image, "#000", $color-E);}
				.scheme-f & {@include svg-fill($internal-form-radio-image, "#000", $color-F);}
				.scheme-g & {@include svg-fill($internal-form-radio-image, "#000", $color-G);}
				.scheme-y & {@include svg-fill($internal-form-radio-image, "#000", $color-Y);}
				.scheme-z & {@include svg-fill($internal-form-radio-image, "#000", $color-Z);}
				.scheme-x & {@include svg-fill($internal-form-radio-image, "#000", $color-X);}
				}
		}


	}


	.uk-form-controls {
		> div {
			margin-top: 16px;
		}

	}

	.uk-form-controls.stacked {
		.control-item {
			margin-top: 0;
		}
		.form-checkbox {
			padding-left: 0;
			input {
				position: static;
				margin-bottom: 8px;
			}
		}
	}




}


// Inverse
// ========================================================================


// @mixin hook-inverse-form(){}
// @mixin hook-inverse-form-focus(){}
// @mixin hook-inverse-form-radio(){}
// @mixin hook-inverse-form-radio-focus(){}
// @mixin hook-inverse-form-radio-checked(){}
// @mixin hook-inverse-form-radio-checked-focus(){}
// @mixin hook-inverse-form-label(){}
