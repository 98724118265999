// Name:            Tab
// Description:     Component to create a tabbed navigation
//
// Component:       `uk-tab`
//
// Modifiers:       `uk-tab-bottom`
//                  `uk-tab-left`
//                  `uk-tab-right`
//
// States:          `uk-active`
//                  `uk-disabled`
//
// ========================================================================


// Variables
// ========================================================================

$tab-margin-horizontal:                   4px !default;

$tab-item-padding-horizontal:             10px !default;
$tab-item-padding-vertical:               8px !default;
$tab-item-color:                          $global-secondary-color !default;
$tab-item-hover-color:                    $global-inverse-color !default;
$tab-item-hover-text-decoration:          none !default;
$tab-item-active-color:                   $global-inverse-color !default;
$tab-item-disabled-color:                 $global-muted-color !default;



// Inverse
// ========================================================================

$inverse-tab-item-color:                        $inverse-global-muted-color !default;
$inverse-tab-item-hover-color:                  $inverse-global-color !default;
$inverse-tab-item-active-color:                 $inverse-global-emphasis-color !default;
$inverse-tab-item-disabled-color:               $inverse-global-muted-color !default;


