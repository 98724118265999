// Name:            Base
// Description:     Default values for HTML elements
//
// Component:       `uk-link`
//                  `uk-h1`, `uk-h2`, `uk-h3`, `uk-h4`, `uk-h5`, `uk-h6`
//                  `uk-hr`
//
// ========================================================================


// Variables
// ========================================================================

$base-body-background:                           $global-background !default;
$base-body-font-family:                          $global-font-family !default;
$base-body-font-weight:                          $normal !default;
$base-body-font-size:                            $global-font-size !default;
$base-body-line-height:                          $global-line-height !default;
$base-body-color:                                $global-secondary-color !default;

$base-link-color:                                $global-color !default;
$base-link-text-decoration:                      underline !default;
$base-link-hover-color:                          $global-link-hover-color !default;
$base-link-hover-text-decoration:                underline !default;

$base-strong-font-weight:                        $bold !default;
$base-code-font-size:                            $global-small-font-size !default;
$base-code-font-family:                          Consolas, monaco, monospace !default;
$base-code-color:                                $global-danger-background !default;
$base-em-color:                                  $global-color !default;
$base-ins-background:                            #ffd !default;
$base-ins-color:                                 $global-color !default;
$base-mark-background:                           #ffd !default;
$base-mark-color:                                $global-color !default;
$base-quote-font-style:                          italic !default;
$base-small-font-size:                           80% !default;

$base-margin-vertical:                           $global-margin !default;

$base-heading-font-family:                       $global-font-family !default;
$base-heading-font-weight:                       $bold !default;
$base-heading-color:                             $global-secondary-color !default;
$base-heading-text-transform:                    none !default;
$base-heading-margin-top:                        $global-medium-margin !default;
$base-h1-font-size-m:                            $global-xlarge-font-size !default;
$base-h1-font-size:                              $base-h1-font-size-m * 0.8695 !default;
$base-h1-line-height:                            (60 / 50 ) !default;
$base-h2-font-size-m:                            $global-large-font-size !default;
$base-h2-font-size:                              $base-h2-font-size-m * 0.7 !default;
$base-h2-line-height:                            (50 / 40) !default;
$base-h3-font-size:                              $global-mediumlarge-font-size !default;
$base-h3-line-height:                            (41 / 30) !default;
$base-h4-font-size:                              $global-medium-font-size !default;
$base-h4-line-height:                            (30 / 24) !default;
$base-h5-font-size:                              1.1111111rem !default;
$base-h5-line-height:                            (28 / 18) !default;
$base-h6-font-size:                              $global-standard-font-size !default;
$base-h6-line-height:                            (22 / 16) !default;

$base-list-padding-left:                         20px !default;

$base-hr-margin-vertical:                        $global-margin !default;
$base-hr-border-width:                           1px !default;
$base-hr-border:                                 #2F2F2F !default;

$base-blockquote-font-size:                      1.07692rem !default;
$base-blockquote-line-height:                    (54 / 40) !default;
$base-blockquote-font-style:                     normal !default;
$base-blockquote-margin-vertical:                0 !default;
$base-blockquote-footer-margin-top:              $global-margin !default;
$base-blockquote-footer-font-size:               0.61538rem !default;
$base-blockquote-footer-line-height:             (22 / 18) !default;

$base-pre-font-size:                             $global-small-font-size !default;
$base-pre-line-height:                           1.5 !default;
$base-pre-font-family:                           $base-code-font-family !default;
$base-pre-color:                                 $global-color !default;

$base-selection-background:                      #39f !default;
$base-selection-color:                           $global-inverse-color !default;



// Inverse
// ========================================================================

$inverse-base-color:                            $inverse-global-color !default;
$inverse-base-link-color:                       $inverse-global-emphasis-color !default;
$inverse-base-link-hover-color:                 $inverse-global-emphasis-color !default;
$inverse-base-code-color:                       $inverse-global-color !default;
$inverse-base-em-color:                         $inverse-global-emphasis-color !default;
$inverse-base-heading-color:                    $inverse-global-color !default;
$inverse-base-hr-border:                        $inverse-global-border !default;


