
.page {padding-top:.1px;padding-bottom: .1px;}


/***************************************************************
  Page Header
  **************************************************************  */

.page-header {

  .nav-container {
    padding: 0;
    background: linear-gradient(90deg, #0A1F5D 0%, #000732 100%);
    @include breakpoint($breakpoint-small) {
    }
  }

  .uk-navbar-nav {
    display: none;
  }

  .uk-navbar-left{
    max-width: 300px;
  }
  a {
    color: $global-inverse-color;
  }

  .uk-navbar-nav {
    transform: translateY(20px);
    @include breakpoint($breakpoint-large) {
      transform: translateY(23px);
    }
  }

  .header-logo {

  }

  .logo--tlh {
    padding: 20px 20px 20px 0;
    position: relative;
    display: block;
    z-index:1;


    @include breakpoint($breakpoint-large) {
      padding: 20px 50px 20px 0;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        display: block;
        left: 50%;
        top: 0;
        height: 100%;
        width: 134.843205%;
        transform: translateX(-47%);
        background-image: linear-gradient(90deg, rgba(#081A51, .1) 7%, #024186 26%, #00529E 36%, #06356A 42%, #001849 49%, #000B36 60%, #000732 76%, rgba(#000732, .05));
      }
    }

    @include breakpoint(max-width $breakpoint-xsmall-max) {
      padding: 10px 20px 10px 0;
      img {
        height: 47px;
      }
    }
  }
  .logo--dc {
    position: relative;
    z-index: 1;
    padding: 10px 0;
    @include breakpoint(max-width $breakpoint-xsmall-max) {
      img {
        height: 30px;
      }
    }
  }

  .dc-tools {
    a {
      margin-left: 1em;
      &:first-child {
        margin-left: 0;
      }
      svg {
        fill: #FFF;
        width: 2em;
        height: 2em;
      }

      &:hover svg {
        fill: $global-emphasis-color;
      }
    }

  }

}
.page-header .uk-sticky-fixed {

  @include breakpoint($breakpoint-medium) {
    .uk-navbar-nav {
      display: flex;
    }
  }



}

.page-header.header-overlay {
  //position: absolute;
  //z-index: 980;
  //left: 0;
  //right: 0;
}



/***************************************************************
  MISC
  **************************************************************  */

.uk-margin-tiny-right {
  margin-right: 14px;
}
.uk-margin-tiny-top {
  margin-top: 14px!important;
}

/***************************************************************
  Footer
  **************************************************************  */

.page-footer {

  .footer-headline {
    font-size: 1.111111rem;
    font-weight: $bold;
    line-height: (27 / 20);
  }
  .footer-main {
    padding-top: $global-margin;
    padding-bottom: $global-large-margin;

    @include breakpoint($breakpoint-medium) {
      padding-top: $global-medium-margin;
      padding-bottom: $global-xlarge-margin;

    }
  }

  .footer-nav {
    &,  li {
      list-style: none;
      padding: 0;
    }
    li {
      padding: .3em 0;
      &:first-child {
        padding-top: 0;
      }
    }

    a {

      &:hover {
        color: currentColor;
        text-decoration: underline;
      }
    }
  }

  a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      color: currentColor;
      text-decoration: underline;
    }
  }

  .uk-list > :nth-child(n+2), .uk-list > * > ul {
    margin-top: 10px;
  }

  .footer-links-inline {
    a {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      @include breakpoint($breakpoint-small) {
        margin-right: 1.7em;
      }
    }
  }

  .footer-text {
    p {
      margin: 10px 0;
    }
  }

}




a.more-link {
  color: $global-color;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 3px solid $global-emphasis-color;
  padding-bottom: 4px;
  font-size: 15px;
  letter-spacing: 0.83px;

  &:hover {
    color: $global-emphasis-color;
  }

  span.less {display: none}

  &.open {
    span:first-child {display: none;}
    span.less {display: inline}
  }

}

.hover-zoom {
  overflow: hidden;
  .uk-cover-container {
    transition: transform ease-in 0.3s;
    transform: scale(1);
  }
}
.uk-link-toggle:hover {
  .hover-zoom .uk-cover-container {
    transform: scale(1.14);
  }
}

.no-hover {
  pointer-events: none;
}

.toggle-exit {
  background-color: rgba(#FFFFFF, .6);
}

.number-big {
  display: inline-block;
  position: relative;
  border-radius: 100%;
  width: 100%;
  height: auto;
  padding-top: 100%;
  background: $global-emphasis-color;
  transform: translateY(-21%);

  &:before {
    content: attr(data-number);
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    font-weight: $bold;
    letter-spacing: .3px;
    color: #FFF;
    @include breakpoint($breakpoint-small) {
      font-size: $global-large-font-size;
    }
  }

}

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

#scroll-down {
  display: block;
  position: fixed;
  bottom: 1.66666em;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0A1F5D;
  width: 3.333333em;
  height: 3.333333em;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  border-radius: 100em;
  text-align: center;
  cursor: pointer;
  z-index: 1111;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    height: 55%;
  }
}