@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=627ddbac-4ef6-4d79-88d2-1ff5728873fc&fontids=6149637,6149645,6149646,6149670,6149673");

@font-face{
  font-family:"Avenir Next LT";
  font-weight: 500;
  font-style: normal;
  src:url("../fonts/6149670/f5d4f93b-454a-43e0-9ae8-7f1271b5c0fd.woff2") format("woff2"),
  url("../fonts/6149670/72556e8c-c677-48d8-81ff-3eb8fc50033b.woff") format("woff");
}
@font-face{
  font-family:"Avenir Next LT";
  font-weight: 500;
  font-style: italic;
  src:url("../fonts/6149673/b7a66ef0-2d88-45d3-bc84-e2d6013878d9.woff2") format("woff2"),
  url("../fonts/6149673/351c1f33-8a8e-41c9-920c-0031d3983321.woff") format("woff");
}
@font-face{
  font-family:"Avenir Next LT";
  font-weight: 600;
  font-style: normal;
  src:url("../fonts/6149637/6afe4676-059a-4aa8-b891-29856bbcba22.woff2") format("woff2"),
  url("../fonts/6149637/a9817c16-2a79-49bf-8543-6c846d73a52f.woff") format("woff");
}
@font-face{
  font-family:"Avenir Next LT";
  font-weight: 600;
  font-style: italic;
  src:url("../fonts/6149646/e9540909-1e28-4c00-9423-10e43c828f31.woff2") format("woff2"),
  url("../fonts/6149646/691d198c-3c78-40b5-9ce3-c92eda0bab0f.woff") format("woff");
}

@font-face{
  font-family:"Avenir Next LT";
  font-weight: 700;
  font-style: normal;
  src:url("../fonts/6149645/b56355b0-7729-42a7-9f65-62c7211d078c.woff2") format("woff2"),
  url("../fonts/6149645/05537afc-e923-4df9-b365-71798fbd220d.woff") format("woff");
}


