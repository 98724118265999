// SVG icons
$icons: (
        'mail-filled',
        'linkedin',
        'twitter',
        'facebook',
        'xing'
);

.svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: 2.5em;
  height: 2.5rem;
  fill: $global-emphasis-color;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  transition: all .2s ease-in;
  p & {
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
  }
}

.icon.icon--question-intro {
  width: 14em;
  height: auto;
  position: absolute;
  left: -12em;
  transform: translateY(-10%);
}


.icon--teaser {
  width: auto;
  height: 70.7%;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);

  &.icon--color-wbz {
    fill: $global-emphasis-color;
  }
}



.icon.icon--trenner {
  max-width: 250px;
  max-height: 250px;
  width: 90%;
  height: 90%;

  &.mobile {
    width: 100px;
    height: 100px;
  }
}
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  fill: #fff;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  transform: translateY(-0.7px); // slightly improve positioning
}


@mixin icon($iconname) {

  .icon-#{$iconname} {background-image: inline-svg($iconname, $global-emphasis-color);}
  .uk-button.icon-#{$iconname} {background-image: inline-svg($iconname, $global-color);}
  .share-links a .icon-#{$iconname} {background-image: inline-svg($iconname, $global-emphasis-color);}
  .share-links a:hover .icon-#{$iconname} {background-image: inline-svg($iconname, $global-emphasis-color);}
  footer .icon-#{$iconname} {background-image: inline-svg($iconname, #FFFFFF);}
}

@each $iconname in $icons {
  @include icon($iconname);
}


.icon.svg {
  max-width: none;
}

.icon.icon--link {
  width: 1.8em;
  height: 1.8em;
  transform: translateY(-.2em);
  margin-right: 4px;
}

.uk-button svg  {
  width: 1.4em;
  height: 1.4em;
  margin-right: 8px;
  fill: $global-emphasis-color;
  transition: .2s all ease-in;

  @include breakpoint($breakpoint-small) {
    width: 1.675em;
    height: 1.675em;
    margin-right: 16px;
  }

  &.icon--mailto {
    margin-right: 7px;
  }

}

.tool-save {
  color: $global-emphasis-color;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 14px;

  svg {
    width: 1.65em;
    height: 1.65em;
    margin-right: 10px;
    fill: currentColor;
  }

  &:hover {
    text-decoration: none;
    color: $global-color;
  }

  @include breakpoint(max-width $breakpoint-xsmall-max) {
    padding: 10px;
    .buttonlabel {display:none;}
    svg {margin-right: 0;}
  }
}


.share-links a {
  background: transparent;
  border: 2px solid $global-emphasis-color;
  display: inline-block;
  margin-left: 18px;
  color: currentColor;

  &:hover {
    background: $inverse-global-color;
    border-color: $inverse-global-color;
  }
  &:first-child {margin-left: 0;}

  .icon {
    width: 2.8em;
    height: 2.8em;
    margin-right: 0;
    background-repeat:no-repeat;
    background-position: center;
  }


  @include breakpoint($breakpoint-medium) {
    padding: .25em;

    .icon {
      width: 2.8em;
      height: 2.8em;
    }
  }

}


/*  INDIKATOREN (Ampel  */

.indicator {
  position: relative;
  padding-left: 1.75em;
  display: inline-block;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.1em;
    height: 1.1em;
    border-radius: 100%;
    background-color: white;
    left: 0;
    top: .2em;
  }

  &.indicator-red:before {background-color: $red;}
  &.indicator-yellow:before {background-color: $yellow;}
  &.indicator-green:before {background-color: $green;}

  &.indicator-large {
    padding-left: 2.4em;
    &:before {
      width: 1.4em;
      height: 1.4em;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}