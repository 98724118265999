// Name:            Off-canvas
// Description:     Component to create an off-canvas sidebar
//
// Component:       `uk-offcanvas`
//
// Sub-objects:     `uk-offcanvas-bar`
//                  `uk-offcanvas-container`
//                  `uk-offcanvas-page`
//
// Adopted:         `uk-offcanvas-close`
//
// Modifiers:       `uk-offcanvas-flip`
//                  `uk-offcanvas-bar-animation`
//                  `uk-offcanvas-reveal`
//                  `uk-offcanvas-overlay`
//                  `uk-offcanvas-container-animation`
//
// States:          `uk-open`
//
// ========================================================================


// Hooks
// ========================================================================
 @mixin hook-offcanvas-bar(){
   .offcanvas-evaluation & {
     @include breakpoint(890px) {
     width: 890px;
     }
     padding-left: 0;
     padding-right: 0;
   }
 }
 @mixin hook-offcanvas-close(){
    position: static;
    padding: inherit;
    right: auto;
    z-index:auto;
 }
// @mixin hook-offcanvas-overlay(){}
 @mixin hook-offcanvas-misc(){

   .offcanvas-nav-bottom {
     position: relative;
     height: 4em;
   }
   .offcanvas-nav {
     position: absolute;
     right:24px;
     top: 20px;


     .nav-item {
       display: inline-block;
       margin-left: 10px;

       &:first-child {
         margin: 0;
       }
       svg {
         width: 1.6725em;
         height: 1.6725em;
         fill: $global-emphasis-color;
       }

       &:hover svg {
         fill: $global-color;
       }
     }

   }
 }

