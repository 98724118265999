// Name:            Dropdown
// Description:     Component to create dropdown menus
//
// Component:       `uk-dropdown`
//
// Adopted:         `uk-dropdown-nav`
//
// Modifiers:       `uk-dropdown-top-*`
//                  `uk-dropdown-bottom-*`
//                  `uk-dropdown-left-*`
//                  `uk-dropdown-right-*`
//                  `uk-dropdown-stack`
//                  `uk-dropdown-grid`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$dropdown-z-index:                               $global-z-index + 20 !default;
$dropdown-min-width:                             200px !default;
$dropdown-padding:                               15px !default;
$dropdown-background:                            $global-muted-background !default;
$dropdown-color:                                 $global-color !default;
$dropdown-margin:                                $global-small-margin !default;

$dropdown-nav-item-color:                        $global-muted-color !default;
$dropdown-nav-item-hover-color:                  $global-color !default;
$dropdown-nav-header-color:                      $global-emphasis-color !default;
$dropdown-nav-divider-border-width:              $global-border-width !default;
$dropdown-nav-divider-border:                    $global-border !default;
$dropdown-nav-sublist-item-color:                $global-muted-color !default;
$dropdown-nav-sublist-item-hover-color:          $global-color !default;
