// Name:            Text
// Description:     Utilities for text
//
// Component:       `uk-text-*`
//
// ========================================================================


// Variables
// ========================================================================

$text-lead-font-size:                            $global-standard-font-size !default;
$text-lead-line-height:                          (30 / 18) !default;
$text-lead-color:                                $base-heading-color !default;

$text-meta-font-size:                            .8rem !default;
$text-meta-line-height:                          1.5 !default;
$text-meta-color:                                $global-color !default;

$text-small-font-size:                           $global-small-font-size !default;
$text-small-line-height:                         1.5 !default;

$text-large-font-size:                           1.6rem !default;
$text-large-line-height:                         (42 / 32) !default;

$text-muted-color:                               $global-muted-color !default;
$text-emphasis-color:                            $global-emphasis-color !default;
$text-primary-color:                             $global-primary-background !default;
$text-secondary-color:                           $global-secondary-background !default;
$text-success-color:                             $global-success-background !default;
$text-warning-color:                             $global-warning-background !default;
$text-danger-color:                              $global-danger-background !default;

$text-background-color:                          $global-primary-background !default;



// Inverse
// ========================================================================

$inverse-text-lead-color:                       $inverse-global-color !default;
$inverse-text-meta-color:                       $inverse-global-muted-color !default;
$inverse-text-muted-color:                      $inverse-global-muted-color !default;
$inverse-text-emphasis-color:                   $inverse-global-emphasis-color !default;
$inverse-text-primary-color:                    $inverse-global-primary-background !default;
$inverse-text-secondary-color:                  $inverse-global-primary-background !default;


