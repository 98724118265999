// Name:            Button
// Description:     Styles for buttons
//
// Component:       `uk-button`
//
// Sub-objects:     `uk-button-group`
//
// Modifiers:       `uk-button-default`
//                  `uk-button-primary`
//                  `uk-button-secondary`
//                  `uk-button-danger`
//                  `uk-button-text`
//                  `uk-button-link`
//                  `uk-button-small`
//                  `uk-button-large`
//
// States:          `uk-active`
//
// ========================================================================

@mixin hook-button(){
 transition: all .2s ease-in;
  position: relative;
  border: none;
  font-weight: $semibold;



  &[class*="icon-"] {
    padding-left: ($global-medium-gutter + 15px);
    background-repeat: no-repeat;
    background-position: .5em center;
  }

  @include breakpoint(max-width $breakpoint-xsmall-max) {
    font-size: 14px;
    padding: 0 10px;
    line-height: 40px;
    min-width: 0;

    &[class*="icon-"] {
      padding-left: ($global-medium-gutter );
    }
  }

}
// @mixin hook-button-hover(){}
// @mixin hook-button-focus(){}
// @mixin hook-button-active(){}
 @mixin hook-button-default(){
   border: 2px solid $global-secondary-background;
   min-width: 120px;


   @include breakpoint($breakpoint-small) {
     min-width: 200px;
   }

   .uk-light &, &.uk-light {
     border-color: $inverse-button-default-color;

     &:hover {
       border-color: $global-inverse-color;
     }
   }
 }
 @mixin hook-button-default-hover(){
 }
// @mixin hook-button-default-active(){}
 @mixin hook-button-primary(){
   min-width: 120px;
   @include breakpoint($breakpoint-small) {
     min-width: 200px;
   }
   border: 2px solid $global-primary-background;

   &[disabled], &[disabled]:hover {
     border-color: $button-disabled-background;
     background-color: $button-disabled-background!important;
     cursor: auto;
   }

   &.outlined {
     background: transparent;
     color:$inverse-button-primary-color;
   }

 }
 @mixin hook-button-primary-hover(){
   border-color: $button-primary-hover-background;

   &.hover-white {
     background-color: #FFFFFF;
     border-color: #FFFFFF;
     color: $inverse-button-primary-color;
   }

   &.outlined.hover-white {
     background: white;
     color: $global-color;
     border-color: white;
   }

   svg {
     fill: #FFF;
   }
 }
// @mixin hook-button-primary-active(){}
 @mixin hook-button-secondary(){
   min-width: 200px;
   border: 2px solid $global-secondary-background;

   &[disabled], &[disabled]:hover {
     border-color: $button-disabled-background;
     background-color: $button-disabled-background!important;
     cursor: auto;
   }

   @include breakpoint(max-width $breakpoint-xsmall-max) {
     min-width: 0;
   }

   &.outlined {
     background: transparent;
     color: $global-secondary-background;
   }
 }
 @mixin hook-button-secondary-hover(){
   border-color: $global-primary-background;

   &.outlined.hover-white {
     background: white;
     color: $global-color;
     border-color: white;
   }

   svg {
     fill: #FFF;
   }


 }
// @mixin hook-button-secondary-active(){}
// @mixin hook-button-danger(){}
// @mixin hook-button-danger-hover(){}
// @mixin hook-button-danger-active(){}
 @mixin hook-button-disabled(){
   cursor: auto;
 }
 @mixin hook-button-small(){
   padding-left: 10px;
   padding-right: 10px;
 }
// @mixin hook-button-large(){}
 @mixin hook-button-text(){
 }
 @mixin hook-button-text-hover(){

 }
// @mixin hook-button-text-disabled(){}
// @mixin hook-button-link(){}
 @mixin hook-button-misc(){
   .uk-button.uk-button-icon {
     min-width: 44px;
     padding: 0;
     text-align:center;
    svg {
      margin-right: 0;
    }
     @include breakpoint($breakpoint-small) {
       min-width: 60px;
     }
   }

   .uk-button[data-slidetoggle]  {
     svg {transform: translateX(0);}
     &.open svg{
       transform: translateY(0) rotate(180deg);
     }
   }


 }



// Inverse
// ========================================================================

 @mixin hook-inverse-button-default(){
   svg {
     fill: $global-emphasis-color;
   }
 }
// @mixin hook-inverse-button-default-hover(){}
// @mixin hook-inverse-button-default-active(){}
 @mixin hook-inverse-button-primary(){
   border-color: $global-primary-background;

 }
 @mixin hook-inverse-button-primary-hover(){
   border-color: $global-primary-background;
 }
// @mixin hook-inverse-button-primary-active(){}
 @mixin hook-inverse-button-secondary(){
   border-color: $global-secondary-background;


 }
 @mixin hook-inverse-button-secondary-hover(){
   border-color: $global-inverse-color;
 }
// @mixin hook-inverse-button-secondary-active(){}
// @mixin hook-inverse-button-text(){}
// @mixin hook-inverse-button-text-hover(){}
// @mixin hook-inverse-button-text-disabled(){}
// @mixin hook-inverse-button-link(){}
