// Name:            Table
// Description:     Styles for tables
//
// Component:       `uk-table`
//
// Modifiers:       `uk-table-middle`
//                  `uk-table-divider`
//                  `uk-table-striped`
//                  `uk-table-hover`
//                  `uk-table-small`
//                  `uk-table-justify`
//                  `uk-table-shrink`
//                  `uk-table-expand`
//                  `uk-table-link`
//                  `uk-table-responsive`
//
// States:          `uk-active`
//
// ========================================================================



// Hooks
// ========================================================================


// @mixin hook-table(){}
// @mixin hook-table-header-cell(){}
 @mixin hook-table-cell(){
   font-weight: $bold;
 }
// @mixin hook-table-footer(){}
// @mixin hook-table-caption(){}
// @mixin hook-table-row-active(){}
// @mixin hook-table-divider(){}
// @mixin hook-table-striped(){}
// @mixin hook-table-hover(){}
// @mixin hook-table-small(){}
// @mixin hook-table-large(){}
// @mixin hook-table-misc(){}


// Inverse
// ========================================================================


// @mixin hook-inverse-table-header-cell(){}
// @mixin hook-inverse-table-caption(){}
// @mixin hook-inverse-table-row-active(){}
// @mixin hook-inverse-table-divider(){}
// @mixin hook-inverse-table-striped(){}
// @mixin hook-inverse-table-hover(){}