.dc-hidden {
    display: none;
}

.question-list {
    li.dc-disabled {
//        background-color: #eee;
        &:before {
            content: "";
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            background: rgba(#FFF,0);
            z-index: 100
        }
        > *:not(.dismiss) {
            opacity: .5;

        }
    }    
}
