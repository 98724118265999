// Name:            Navbar
// Description:     Component to create horizontal navigation bars
//
// Component:       `uk-navbar`
//
// Sub-objects:     `uk-navbar-container`
//                  `uk-navbar-left`
//                  `uk-navbar-right`
//                  `uk-navbar-center`
//                  `uk-navbar-center-left`
//                  `uk-navbar-center-right`
//                  `uk-navbar-nav`
//                  `uk-navbar-item`
//                  `uk-navbar-toggle`
//                  `uk-navbar-subtitle`
//                  `uk-navbar-dropbar`
//
// Adopted:         `uk-navbar-dropdown` + Modifiers
//                  `uk-navbar-dropdown-nav`
//                  `uk-navbar-dropdown-grid`
//                  `uk-navbar-toggle-icon`
//
// Modifiers:       `uk-navbar-transparent`
//                  `uk-navbar-sticky`
//                  `uk-navbar-dropdown-stack`
//
// States:          `uk-active`
//                  `uk-parent`
//                  `uk-open`
//
//
// ========================================================================


@mixin hook-navbar(){

  .uk-navbar-nav {

  }
  .uk-navbar-nav > li {

  }

}
 @mixin hook-navbar-container(){
   padding: 0;

   .uk-sticky-fixed & {
     //padding-top: 3px;
     //padding-bottom: 3px;
   }
 }
@mixin hook-navbar-nav-item(){

  margin-left: 10px;
}
 @mixin hook-navbar-nav-item-hover(){
   background: $global-inverse-color;

 }
 @mixin hook-navbar-nav-item-onclick(){
   background: $global-emphasis-color;
 }
@mixin hook-navbar-nav-item-active(){
  background: $global-inverse-color;
}
 @mixin hook-navbar-item(){
   @include breakpoint(max-width $breakpoint-xsmall-max) {
     padding-left: 12px;
     padding-right: 12px;
   }
 }
 @mixin hook-navbar-toggle(){
   @include breakpoint($breakpoint-small) {
     min-height: 60px;
   }

 }
 @mixin hook-navbar-toggle-hover(){
 }
// @mixin hook-navbar-toggle-icon(){}
// @mixin hook-navbar-toggle-icon-hover(){}
// @mixin hook-navbar-subtitle(){}
// @mixin hook-navbar-transparent(){}
 @mixin hook-navbar-sticky(){

 }
 @mixin hook-navbar-dropdown(){}
// @mixin hook-navbar-dropdown-dropbar(){}
 @mixin hook-navbar-dropdown-nav(){
   & > li:not(:first-child) {}
 }
 @mixin hook-navbar-dropdown-nav-item(){}
// @mixin hook-navbar-dropdown-nav-item-hover(){}
// @mixin hook-navbar-dropdown-nav-item-active(){}
// @mixin hook-navbar-dropdown-nav-header(){}
// @mixin hook-navbar-dropdown-nav-divider(){}
// @mixin hook-navbar-dropbar(){}
// @mixin hook-navbar-dropbar-slide(){}
 @mixin hook-navbar-misc(){
   .uk-navbar-nav > li.lang-nav {
     position: relative;
     margin-left: 20px;
     &:before {
      content: "";
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 50%;
      background-color: white;
     }
   }
 }


// Inverse
// ========================================================================

 @mixin hook-inverse-navbar-nav-item(){
   background-color: $global-emphasis-color;
 }
 @mixin hook-inverse-navbar-nav-item-hover(){
  background-color: $global-inverse-color;
 }
 @mixin hook-inverse-navbar-nav-item-onclick(){
  background-color: $global-inverse-color;
  }
 @mixin hook-inverse-navbar-nav-item-active(){
   background-color: $global-inverse-color;
   &:hover {
   }
 }
// @mixin hook-inverse-navbar-item(){}
// @mixin hook-inverse-navbar-toggle(){}
// @mixin hook-inverse-navbar-toggle-hover(){}
