// Name:            Card
// Description:     Component to create boxed content containers
//
// Component:       `uk-card`
//
// Sub-objects:     `uk-card-body`
//                  `uk-card-header`
//                  `uk-card-footer`
//                  `uk-card-media-*`
//                  `uk-card-title`
//                  `uk-card-badge`
//
// Modifiers:       `uk-card-hover`
//                  `uk-card-default`
//                  `uk-card-primary`
//                  `uk-card-secondary`
//                  `uk-card-small`
//                  `uk-card-large`
//
// Uses:            `uk-grid-stack`
//
// ========================================================================


// Variables
// ========================================================================

$card-body-padding-horizontal:                  $global-gutter !default;
$card-body-padding-vertical:                    $global-small-gutter !default;

$card-body-padding-horizontal-l:                $global-medium-gutter !default;
$card-body-padding-vertical-l:                  $global-gutter !default;

$card-header-padding-horizontal:                $global-small-gutter !default;
$card-header-padding-vertical:                  round($global-gutter / 2) !default;

$card-header-padding-horizontal-l:              $global-gutter !default;
$card-header-padding-vertical-l:                round($global-gutter / 2) !default;

$card-footer-padding-horizontal:                $global-small-gutter !default;
$card-footer-padding-vertical:                  ($global-gutter / 2) !default;

$card-footer-padding-horizontal-l:              $global-gutter !default;
$card-footer-padding-vertical-l:                round($global-medium-gutter / 2) !default;

$card-title-font-size:                          $global-medium-font-size !default;
$card-title-line-height:                        (30 / 24) !default;

$card-badge-top:                                $global-gutter !default;
$card-badge-right:                              $card-badge-top !default;

$card-hover-background:                         $global-muted-background !default;

$card-default-background:                       #e9ebf2 !default;
$card-default-color:                            $global-color !default;
$card-default-title-color:                      $global-secondary-color !default;
$card-default-hover-background:                 transparent !default;

$card-primary-background:                       $global-secondary-background !default;
$card-primary-color:                            $inverse-global-color !default;
$card-primary-title-color:                      $inverse-global-color !default;
$card-primary-hover-background:                 $global-color !default;
$card-primary-color-mode:                       light !default;

$card-secondary-background:                     $global-primary-background !default;
$card-secondary-color:                          $global-color !default;
$card-secondary-title-color:                    $global-secondary-color !default;
$card-secondary-hover-background:               $global-emphasis-color !default;
$card-secondary-color-mode:                     light !default;

$card-small-body-padding-horizontal:            $global-margin !default;
$card-small-body-padding-vertical:              $global-margin !default;
$card-small-header-padding-horizontal:          $global-margin !default;
$card-small-header-padding-vertical:            round($global-margin / 1.5) !default;
$card-small-footer-padding-horizontal:          $global-margin !default;
$card-small-footer-padding-vertical:            round($global-margin / 1.5) !default;

$card-large-body-padding-horizontal-l:          $global-large-gutter !default;
$card-large-body-padding-vertical-l:            $global-large-gutter !default;
$card-large-header-padding-horizontal-l:        $global-large-gutter !default;
$card-large-header-padding-vertical-l:          round($global-large-gutter / 2) !default;
$card-large-footer-padding-horizontal-l:        $global-large-gutter !default;
$card-large-footer-padding-vertical-l:          round($global-large-gutter / 2) !default;

