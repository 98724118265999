// Name:            Tooltip
// Description:     Component to create tooltips
//
// Component:       `uk-tooltip`
//
// Modifiers        `uk-tooltip-top`
//                  `uk-tooltip-top-left`
//                  `uk-tooltip-top-right`
//                  `uk-tooltip-bottom`
//                  `uk-tooltip-bottom-left`
//                  `uk-tooltip-bottom-right`
//                  `uk-tooltip-left`
//                  `uk-tooltip-right`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$tooltip-z-index:                                $global-z-index + 30 !default;
$tooltip-max-width:                              400px !default;
$tooltip-padding-vertical:                       20px !default;
$tooltip-padding-horizontal:                     20px !default;
$tooltip-background:                             $global-color !default;
$tooltip-border-radius:                          0 !default;
$tooltip-color:                                  $global-inverse-color !default;
$tooltip-font-size:                              $global-small-font-size !default;

$tooltip-margin:                                 10px !default;

