// Name:            Text
// Description:     Utilities for text
//
// Component:       `uk-text-*`
//
// ========================================================================


 @mixin hook-text-lead(){
 }
 //@mixin hook-text-meta(){}
// @mixin hook-text-small(){}
// @mixin hook-text-large(){}
// @mixin hook-text-background(){}
 @mixin hook-text-misc(){
  .uk-text-normal {
   font-weight: $normal;
  }
  .uk-text-white {
   color: #FFFFFF!important;


  }
 }

// Inverse
// ========================================================================


// @mixin hook-inverse-text-lead(){}
// @mixin hook-inverse-text-meta(){}
