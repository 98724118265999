// Name:            Background
// Description:     Utilities for backgrounds
//
// Component:       `uk-background-*`
//
// ========================================================================


 @mixin hook-background-misc(){
   .uk-background-white {
     background: white;
   }

   .uk-background-tertiary {
     background: $background-tertiary-background;
   }
  .uk-background-scheme {
    //background-color: rgba($color-X,.15);
    background-color: white;

    &.scheme-a {background-color: mix($color-A, #FFF, 15);}
    &.scheme-b {background-color: mix($color-B, #FFF, 15);}
    &.scheme-c {background-color: mix($color-C, #FFF, 15);}
    &.scheme-d {background-color: mix($color-D, #FFF, 15);}
    &.scheme-e {background-color: mix($color-E, #FFF, 15);}
    &.scheme-f {background-color: mix($color-F, #FFF, 15);}
    &.scheme-g {background-color: mix($color-G, #FFF, 15);}
    &.scheme-x {background-color: mix($color-X, #FFF, 15);}
    &.scheme-y {background-color: mix($color-Y, #FFF, 15);}
    &.scheme-z {background-color: mix($color-Z, #FFF, 15);}
}

    .uk-background-scheme-primary {
      .scheme-a & {background-color:$color-A}
      .scheme-b & {background-color:$color-B}
      .scheme-c & {background-color:$color-C}
      .scheme-d & {background-color:$color-D}
      .scheme-e & {background-color:$color-E}
      .scheme-f & {background-color:$color-F}
      .scheme-g & {background-color:$color-G}
      .scheme-x & {background-color:$color-X}
      .scheme-y & {background-color:$color-Y}
      .scheme-z & {background-color:$color-Z}
    }


}
