// Name:            Utility
// Description:     Utilities collection
//
// Component:       `uk-panel-*`
//                  `uk-clearfix`
//                  `uk-float-*`
//                  `uk-overflow-*`
//                  `uk-resize-*`
//                  `uk-display-*`
//                  `uk-inline-*`
//                  `uk-responsive-*`
//                  `uk-preserve-width`
//                  `uk-border-*`
//                  `uk-box-shadow-*`
//                  `uk-box-shadow-bottom`
//                  `uk-dropcap`
//                  `uk-logo`
//                  `uk-blend-*`
//                  `uk-transform-*`
//                  `uk-transform-origin-*`
//
// States:          `uk-disabled`
//                  `uk-drag`
//                  `uk-dragover`
//                  `uk-preserve`
//
// ========================================================================


// Hooks
// ========================================================================


// @mixin hook-panel-scrollable(){}
// @mixin hook-box-shadow-bottom(){}
// @mixin hook-dropcap(){}
 @mixin hook-logo(){
   padding: 0;
   width: auto;
   height: 30px;
   @include breakpoint($breakpoint-small) {
    height: 40px;
   }


 }
// @mixin hook-logo-hover(){}
// @mixin hook-utility-misc(){}



// Inverse
// ========================================================================


// @mixin hook-inverse-dropcap(){}
// @mixin hook-inverse-logo(){}
// @mixin hook-inverse-logo-hover(){}