// Name:            Link
// Description:     Styles for links
//
// Component:       `uk-link-muted`
//                  `uk-link-text`
//                  `uk-link-heading`
//                  `uk-link-reset`
//
// ========================================================================



// @mixin hook-link-muted(){}
// @mixin hook-link-muted-hover(){}
// @mixin hook-link-text(){}
 @mixin hook-link-text-hover(){
   transition: all .2s ease-in;
 }
// @mixin hook-link-heading(){}
 @mixin hook-link-heading-hover(){
   transition: all .2s ease-in;
 }
 @mixin hook-link-reset(){
   font-weight: inherit;
 }
 @mixin hook-link-misc(){

   .iconlink {
     text-decoration: none;
     &:hover {
       text-decoration: none;
     }
   }

   .list-downloads {
     a {
       color: $global-emphasis-color;
       font-size: $global-small-font-size;
       font-weight: bold;
       line-height: (22 / 16 );
       border: 2px solid $global-emphasis-color;
       display: block;
       position: relative;
       padding: 15px 22px 15px 80px;

       .uk-icon {
         position: absolute;
         left: 20px;
         top: 15px;
         color: $global-emphasis-color;
       }

       &:hover {
         background-color: #DDECF1;
       }


       @include breakpoint(max-width $breakpoint-xsmall-max) {
         padding: 15px 22px 15px 54px;
         .uk-icon {
           left: 7px
         }
       }
     }
   }


   .link-back {
       color: $global-emphasis-color;
       font-size: $global-small-font-size;
       text-transform: uppercase;
       letter-spacing: .75px;
       font-weight: bold;
       line-height: (22 / 16);
       border: 2px solid $global-emphasis-color;
       display: inline-block;
       position: relative;
       padding: 17px 30px 17px 56px;

       .uk-icon {
         position: absolute;
         left: 10px;
         top:8px;
         color: $global-emphasis-color;
       }

       &:hover {
         background-color: $global-emphasis-color;
         color: #FFFFFF;

         .uk-icon {
           color: #FFFFFF;
         }
       }
   }



   .linklist-divided {
     border-top: 2px solid $global-border;
     a {
       padding: 0.5333333em 0;
       display: block;
       text-decoration: none;
       border-bottom: 2px solid $global-border;
       margin: 0;
     }
   }
 }



// Inverse
// ========================================================================

// @mixin hook-inverse-link-muted(){}
// @mixin hook-inverse-link-muted-hover(){}
// @mixin hook-inverse-link-text-hover(){}
// @mixin hook-inverse-link-heading-hover(){}
