// Name:            Totop
// Description:     Component to create an icon to scroll back to top
//
// Component:       `uk-totop`
//
// ========================================================================


// Variables
// ========================================================================

$totop-padding:                                  5px !default;
$totop-color:                                    $global-muted-color !default;

$totop-hover-color:                              $global-color !default;

$totop-active-color:                             $global-emphasis-color !default;




// Inverse
// ========================================================================

$inverse-totop-color:                        $inverse-global-muted-color !default;
$inverse-totop-hover-color:                  $inverse-global-color !default;
$inverse-totop-active-color:                 $inverse-global-emphasis-color !default;

