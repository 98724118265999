// Name:            Nav
// Description:     Defines styles for list navigations
//
// Component:       `uk-nav`
//
// Sub-objects:     `uk-nav-header`
//                  `uk-nav-divider`
//                  `uk-nav-sub`
//
// Modifiers:       `uk-nav-parent-icon`
//                  `uk-nav-default`
//                  `uk-nav-primary`
//                  `uk-nav-center`
//
// States:          `uk-active`
//                  `uk-parent`
//                  `uk-open`
//                  `uk-touch`
//
// ========================================================================


@mixin hook-nav-sub(){
  border-top: 2px solid $global-emphasis-color;
  margin: 0 0 10px 0;
  padding-top: 10px;

  li {
    border-bottom: 1px solid $global-emphasis-color;
  }
  li a {
     font-size: $global-standard-font-size;
     font-weight: $semibold;
   }

  li:last-child {
    border-bottom: none;
  }
}
 @mixin hook-nav-parent-icon(){
 }
// @mixin hook-nav-header(){}
// @mixin hook-nav-divider(){}
@mixin hook-nav-default(){
}
 @mixin hook-nav-default-item(){
   font-size: 1.2rem;
   padding: .3em 0;
   font-weight: bold;

   @include breakpoint($breakpoint-small) {
    font-size: 1.5rem;
   }
 }
// @mixin hook-nav-default-item-hover(){}
// @mixin hook-nav-default-item-active(){}
// @mixin hook-nav-default-header(){}
// @mixin hook-nav-default-divider(){}
// @mixin hook-nav-primary(){}
 @mixin hook-nav-primary-item(){
 }
// @mixin hook-nav-primary-item-hover(){}
// @mixin hook-nav-primary-item-active(){}
// @mixin hook-nav-primary-header(){}
// @mixin hook-nav-primary-divider(){}
// @mixin hook-nav-misc(){}


// Inverse
// ========================================================================



// @mixin hook-inverse-nav-parent-icon(){}
// @mixin hook-inverse-nav-default-item(){}
// @mixin hook-inverse-nav-default-item-hover(){}
// @mixin hook-inverse-nav-default-item-active(){}
// @mixin hook-inverse-nav-default-header(){}
// @mixin hook-inverse-nav-default-divider(){}
// @mixin hook-inverse-nav-primary-item(){}
// @mixin hook-inverse-nav-primary-item-hover(){}
// @mixin hook-inverse-nav-primary-item-active(){}
// @mixin hook-inverse-nav-primary-header(){}
// @mixin hook-inverse-nav-primary-divider(){}
