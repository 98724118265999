// Name:            Tooltip
// Description:     Component to create tooltips
//
// Component:       `uk-tooltip`
//
// Modifiers        `uk-tooltip-top`
//                  `uk-tooltip-top-left`
//                  `uk-tooltip-top-right`
//                  `uk-tooltip-bottom`
//                  `uk-tooltip-bottom-left`
//                  `uk-tooltip-bottom-right`
//                  `uk-tooltip-left`
//                  `uk-tooltip-right`
//
// States:          `uk-active`
//
// ========================================================================


// Hooks
// ========================================================================


 @mixin hook-tooltip(){
   font-style: italic;
   line-height: (22 / 16);

   @include breakpoint(max-width $breakpoint-small-max) {
     max-width: 280px;
     padding: 14px;
   }

   //&:before {
   //  content: "";
   //  position: absolute;
   //  left: 50%;
   //  transform: translateX(-50%);
   //  top: 100%;
   //  width: 0;
   //  height: 0;
   //  border-left: 24px solid transparent;
   //  border-right: 24px solid transparent;
   //  border-top: 24px solid $tooltip-background;
   //}
 }
// @mixin hook-tooltip-misc(){}
