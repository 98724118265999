// Name:            Lightbox
// Description:     Component to create an lightbox image gallery
//
// Component:       `uk-lightbox`
//
// Sub-objects:     `uk-lightbox-page`
//                  `uk-lightbox-items`
//                  `uk-lightbox-toolbar`
//                  `uk-lightbox-toolbar-icon`
//                  `uk-lightbox-button`
//                  `uk-lightbox-caption`
//                  `uk-lightbox-iframe`
//
// States:          `uk-open`
//
// ========================================================================


// Hooks
// ========================================================================


// @mixin hook-lightbox(){}
// @mixin hook-lightbox-item(){}
// @mixin hook-lightbox-toolbar(){}
// @mixin hook-lightbox-toolbar-icon(){}
// @mixin hook-lightbox-toolbar-icon-hover(){}
// @mixin hook-lightbox-button(){}
 @mixin hook-lightbox-button(){

 }
// @mixin hook-lightbox-button-hover(){}
 @mixin hook-lightbox-misc(){
  .uk-close-large {
   color: $global-emphasis-color;
  }
 }
