// Name:            Accordion
// Description:     Component to create accordions
//
// Component:       `uk-accordion`
//
// Sub-objects:     `uk-accordion-title`
//                  `uk-accordion-content`
//
// States:          `uk-open`
//
// ========================================================================



@mixin hook-accordion(){
}
@mixin hook-accordion-item(){

}
@mixin hook-accordion-title(){
    @include breakpoint(max-width $breakpoint-small-max) {
        font-size: $global-standard-font-size;
    }
    margin-bottom: 0;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    font-weight: $semibold;
    padding-right: 3em;

    &::before {
        content: "";
        width: ($accordion-title-line-height * 1.5em);
        height: ($accordion-title-line-height * 1.5em);
        position: absolute;
        right: 0;
        top: -6px;
        @include svg-fill($internal-accordion-close-image, "#000", $accordion-icon-color);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 1em;

    }

    .uk-open > &::before {
        @include svg-fill($internal-accordion-open-image, "#000", $accordion-icon-color);
    }

    .uk-open & {
    }


    // 2. Variante
    .type-2 & {
        padding: 1em 3em 1em 1em;
        background-color: $global-primary-background;
        color: $global-inverse-color;
        &::before {
            width: ($accordion-title-line-height * 2em);
            height: ($accordion-title-line-height * 2em);
            @include svg-fill($internal-accordion-close-image, "#000", $global-inverse-color);
            background-size: 1.25em;
            top: .3em;
            right: .4em;
        }
    }
    .type-2 .uk-open > &::before {
        @include svg-fill($internal-accordion-open-image, "#000", $global-inverse-color);
    }
}
@mixin hook-accordion-title-hover(){
    .type-2 & {
        color: $global-inverse-color;
        background-color: $global-secondary-background;
    }
}
@mixin hook-accordion-content(){
    color: $global-secondary-color;
    padding-top: .75em;
    padding-bottom: .25em;



    .type-2 & {
        padding: 1em 0 .5em;
    }



    .accordion-content-section {
        padding: .5em 0 2em;
        @include breakpoint($breakpoint-small) {
            padding: .5em 1.5em 2em;
        }
        background-color: white;
        margin-bottom: 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }

}
@mixin hook-accordion-misc(){
    .uk-accordion > * {
        border-top: 2px solid $global-emphasis-color;
        font-weight: normal;
        padding: 1em 0;

        &:last-child {
            border-bottom: 2px solid $global-emphasis-color;
        }
    }

    .uk-accordion.type-2 > * {
        border: none;
        font-weight: normal;
        padding: 0;
        margin-top: 10px;

        &:last-child {
            border: none;
        }
    }
}



// Inverse
// ========================================================================
@mixin hook-inverse-accordion-item(){}
@mixin hook-inverse-accordion-title(){}
@mixin hook-inverse-accordion-title-hover(){}
@mixin hook-inverse-component-accordion(){

    .uk-accordion-title::before { @include svg-fill($internal-accordion-close-image, "#000", $inverse-global-color); }

    .uk-open > .uk-accordion-title::before { @include svg-fill($internal-accordion-open-image, "#000", $inverse-global-color); }

}