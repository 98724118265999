// Name:            Switcher
// Description:     Component to navigate through different content panes
//
// Component:       `uk-switcher`
//
// States:          `uk-active`
//
// ========================================================================


/* ========================================================================
   Component: Switcher
 ========================================================================== */

// Hooks
// ========================================================================

 @mixin hook-switcher-misc(){
   .uk-switcher {
      border: 2px solid $global-emphasis-color;

     padding: 1em 1em 1.5em;
   }
 }