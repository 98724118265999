#Datenschutzerklaerung li{
  list-style-position: initial;
}

#Datenschutzerklaerung {
  margin-left: 0;

  span.counter {
    margin-left: 0;
    float: left;
    font-weight: bold;
    display: block;
    min-width: 0;
    &:after {
      content: "\00A0";
    }
  }


code {
  font-family: $global-font-family;
  font-size: 0.888889rem;
  color: $global-secondary-color;
  white-space: inherit;
  background-color: rgba($global-emphasis-color, .15);
  border-color: 1px solid $global-secondary-color;
  line-height: 1.4;
  padding: 1em;
  letter-spacing: inherit;
}

  > p {
    margin: 10px 0;
  }


  span.counter,
  span.counter + p {
    @extend .uk-h3;
    margin-top: 0!important;
}




}