.ce-txtimg {

	.uk-container,.uk-container-large {
		//@include breakpoint(max-width $breakpoint-xsmall-max) {
		//	padding:0;
		//	margin: 0 -5px;
		//}
	}

	.txtimg-text {
		padding-top: 10px;

		.uk-background-secondary & {
		padding-bottom: 40px;
		}


		@include breakpoint($breakpoint-small) {
			padding-top: 40px;
			padding-bottom: 60px;
		}

		@include breakpoint($breakpoint-medium) {
			padding-top: $global-medium-margin;
			padding-bottom: $global-medium-margin;
		}

		&.uk-padding {
		@include breakpoint(max-width $breakpoint-xsmall-max) {
			padding-left: 15px;
			padding-right: 15px
		}
		}
	}

	.text-max-width {
		max-width: 500px;
	}

	.txtimg-image {
		canvas {
			width: 640px;
			height: 320px;
			@include breakpoint($breakpoint-small) {
				width: 720px;
				height: 500px;
			}
		}


	}

	//.img-holder {
		//@include breakpoint(max-width $breakpoint-xsmall-max) {
		//	padding-left: 0;
		//	margin-right: -20px;
		//}
	//}

	&:not([class*="uk-background"]) {
		.uk-grid-stack .uk-padding {
			padding-bottom: 0;
		}
	}
}
