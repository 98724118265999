// Name:            Drop
// Description:     Component to position any element next to any other element.
//
// Component:       `uk-drop`
//
// Modifiers:       `uk-drop-top-*`
//                  `uk-drop-bottom-*`
//                  `uk-drop-left-*`
//                  `uk-drop-right-*`
//                  `uk-drop-stack`
//                  `uk-drop-grid`
//
// States:          `uk-open`
//
// Uses:            Animation
//
// ========================================================================


 @mixin hook-drop-misc(){
     .uk-drop {
     @include breakpoint($breakpoint-medium) {
        width: 440px;
     }
     }
 }
