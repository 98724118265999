.ce-chapter-start {
  @include breakpoint($breakpoint-small)  {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  /* ADDED */

    &:after { /* ADDED */
      flex: 3;
      content: '';
    }
    &:before { /* ADDED */
      flex: 1;
      content: '';
    }
  }
}


.question {
  margin-top: 0;
  @include breakpoint($breakpoint-medium) {
    margin-top: 20px;
  }
  @include breakpoint($breakpoint-large) {
    margin-top: 40px;
  }
}
.question-intro {
  .pretitle {
    @include breakpoint(max-width $breakpoint-small-max) {
      padding-right: 3em;
    }
  }
}


.question-list {
  &, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > li {
    background: $global-background;
    margin: $global-small-margin 0;
    padding: $global-margin;
    position: relative;

    @include breakpoint(max-width $breakpoint-medium-max) {
      &.dismissable {
        margin-top: ($global-small-margin + 30px);
      }
    }
  }

  .dismiss {
    display: block;
    background: $global-background;
    width: 60px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 100%;
    text-align:center;

    .uk-switch {
      margin-top: 8px;
    }

     @include breakpoint($breakpoint-large) {
       width: 50px;
       height: 50px;
       left: 100%;
       top: 0;
       .uk-switch {
         margin-top: 15px;
       }
     }


  }


  .question-text {

    .uk-icon {
      color: $global-emphasis-color;
      transform: translateY(-3px);

      &[uk-tooltip] {
      cursor: pointer;
      }
    }
  }
}


.question-control-list {
  @include breakpoint($breakpoint-small) {
    padding-left: calc(6% + 20px);
  }
}

.dc-radio-icon {
  display: block;
  cursor: pointer;

  text-align: center;

  input {display: none}

  input:checked + .dc-radio-icon-wrapper {
    background-color: rgba($global-emphasis-color, .1);
    color: $global-emphasis-color;
  }

  & > .dc-radio-icon-wrapper {
    display: block;
    transition: all .2s ease-in;
    padding: 5px 0;
  }

  svg {
    display: block;
    width: 4em;
    height: 4em;
    margin: 0 auto;
    fill: $global-emphasis-color;

    @include breakpoint($breakpoint-medium) {
      width: 5.5em;
      height: 5.5em;
    }
  }

  .dc-radio-icon-text {
    display: block;
    font-weight: $bold;
    font-size: $global-small-font-size;
    line-height: (22 / 16);
  }

  &:hover {
    >  .dc-radio-icon-wrapper{background-color: rgba($global-emphasis-color, .1);}
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.dc-plz {

  & > div {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -3px;
      bottom: 0;
      background: white;
      width: 6px;
      height: 2px;
    }
  }

  input {
    height: 50px;
    font-weight: $bold;
    font-size: $global-medium-font-size;
    border: none;
    border-bottom: 2px solid $global-emphasis-color;
    width: 1.8em;
    text-align: left;
    background: none;
    letter-spacing: .2em;
    padding-left: .15em;
    padding-right: 0;

    @include breakpoint($breakpoint-small) {
      font-size: $global-large-font-size;
    }

    &::placeholder {
      color: $global-muted-background;
    }
  }
}


/*  Buttons */

.question {
  @include breakpoint (max-width $breakpoint-xsmall-max) {
    .uk-button.button-prev {
      min-width: 0;
    }
  }
}