.contact-list {
    .contact-item {
      margin-top: 10px;
      @include breakpoint($breakpoint-small) {
        margin-top: 20px;
      }
    }

  .institution {
    @include breakpoint($breakpoint-medium) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}


.ce-contacts {

  .uk-switcher {
    padding: 0;
  }
}