// Name:            Search
// Description:     Component to create the search
//
// Component:       `uk-search`
//
// Sub-objects:     `uk-search-input`
//                  `uk-search-toggle`
//
// Adopted:         `uk-search-icon`
//
// Modifier:        `uk-search-default`
//                  `uk-search-navbar`
//                  `uk-search-large`
//
// ========================================================================

// Hooks
// ========================================================================


 @mixin hook-search-input(){
   border: 2px solid $global-muted-background;
 }
// @mixin hook-search-default-input(){}
 @mixin hook-search-default-input-focus(){
   border: 2px solid $global-emphasis-color;
 }
 @mixin hook-search-navbar-input(){
   padding-left: 16px;
   padding-right: 16px;
   border: 2px solid $global-inverse-color;
 }
// @mixin hook-search-large-input(){}

// @mixin hook-search-toggle(){}
// @mixin hook-search-toggle-hover(){}

 @mixin hook-search-misc(){
   #search-bar .uk-modal-body {
     padding: 0;
   }

 }


// Inverse
// ========================================================================

// @mixin hook-inverse-search-default-input(){}
// @mixin hook-inverse-search-default-input-focus(){}
// @mixin hook-inverse-search-navbar-input(){}
// @mixin hook-inverse-search-large-input(){}
// @mixin hook-inverse-search-toggle(){}
// @mixin hook-inverse-search-toggle-hover(){}
