.ce-text, .copytext {

p + ul {
  margin-top: -20px;
}
  ul {
    @include breakpoint($breakpoint-small) {
    margin: 0 0 0 1.5em;
    }
    padding: 0;
    list-style: none;

    &:first-child {
      margin-top: 0;
    }

    li {
      list-style: none;
      margin: 0.3em 0 0;
      padding: 0 0 0 1.5em;
      position: relative;

      &:first-child {
        margin-top:0;
      }

      &:before {
        content: "•";
        display: block;
        position: absolute;
        left: 0;
        color: $global-color;
      }
    }
  }



  a {

    &:hover {
    }


  }

  .iwcc-cookie {
    background: none;
    border-left: none;
    padding: 0;
  }

}

.ce-text .copytext ul {
  margin: 0;
}
.copytext {

  h2, .uk-h2, h2.uk-h2 {

  }
 blockquote {
   font-size: $global-standard-font-size;
  font-weight: $normal;
  font-style: italic;
   color: $global-secondary-color;
 }


  .linklist {
    &, li {
      padding: 0;

    }

    li {
      margin-top: .7em;
      list-style: none;

      &:first-child {
        margin-top: 0;
      }
      &:before {
        display: none;
      }
    }
  }

  a:not([href*=mailto]) {
    position: relative;
    //padding-left: 2em;
    text-decoration: underline;
    display: inline-block;
    color: $global-emphasis-color;

    //&:after {
    //  content: "";
    //  display: block;
    //  position: absolute;
    //  left: 0;
    //  top: .2em;
    //  width: 2em;
    //  height: 1.2em;
    //  background-size: contain;
    //}

  }


}