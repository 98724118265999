
// Hooks
// ========================================================================


 @mixin hook-grid-misc(){
   .uk-grid-margin-neg {
     margin-top: -1px;
   }


   .uk-grid.grid-margin-tiny\@xs > .uk-grid-margin {
     @include breakpoint(max-width $breakpoint-xsmall-max) {
       margin-top: 10px;
     }
   }
 }

