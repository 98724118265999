// Name:            Padding
// Description:     Utilities for padding
//
// Component:       `uk-padding`
//                  `uk-padding-large`
//                  `uk-padding-remove-*`
//
// ========================================================================



// Hooks
// ========================================================================

 @mixin hook-padding-misc() {

     // my additional custom value:
     $padding-xsmall-padding: 5px !default; // could use a new set value for $global-xsmall-gutter !default;
     $padding-xlarge-padding: 60px !default; // could use a new set value for $global-xsmall-gutter !default;

     .uk-padding-xsmall {
         padding: $padding-xsmall-padding;
     }
     .uk-padding-xsmall-t {
         padding-top: $padding-xsmall-padding;
     }
     .uk-padding-xsmall-r {
         padding-right: $padding-xsmall-padding;
     }
     .uk-padding-xsmall-b {
         padding-bottom: $padding-xsmall-padding;
     }
     .uk-padding-xsmall-l {
         padding-left: $padding-xsmall-padding;
     }
     .uk-padding-xsmall-v {
         padding-top: $padding-xsmall-padding;
         padding-bottom: $padding-xsmall-padding;
     }
     .uk-padding-xsmall-h {
         padding-left: $padding-xsmall-padding;
         padding-right: $padding-xsmall-padding;
     }

     .uk-padding-small {
         padding: $padding-small-padding;
     }
     .uk-padding-small-t {
         padding-top: $padding-small-padding;
     }
     .uk-padding-small-r {
         padding-right: $padding-small-padding;
     }
     .uk-padding-small-b {
         padding-bottom: $padding-small-padding;
     }
     .uk-padding-small-l {
         padding-left: $padding-small-padding;
     }
     .uk-padding-small-v {
         padding-top: $padding-small-padding;
         padding-bottom: $padding-small-padding;
     }
     .uk-padding-small-h {
         padding-left: $padding-small-padding;
         padding-right: $padding-small-padding;
     }

     .uk-padding {
         padding: $padding-padding;
     }
     .uk-padding-t {
         padding-top: $padding-padding;
     }
     .uk-padding-r {
         padding-right: $padding-padding;
     }
     .uk-padding-b {
         padding-bottom: $padding-padding;
     }
     .uk-padding-l {
         padding-left: $padding-padding;
     }
     .uk-padding-v {
         padding-top: $padding-padding;
         padding-bottom: $padding-padding;
     }
     .uk-padding-h {
         padding-left: $padding-padding;
         padding-right: $padding-padding;
     }

     .uk-padding-large {
         padding: $padding-large-padding;
     }
     .uk-padding-large-t {
         padding-top: $padding-large-padding;
     }
     .uk-padding-large-r {
         padding-right: $padding-large-padding;
     }
     .uk-padding-large-b {
         padding-bottom: $padding-large-padding;
     }
     .uk-padding-large-l {
         padding-left: $padding-large-padding;
     }
     .uk-padding-large-v {
         padding-top: $padding-large-padding;
         padding-bottom: $padding-large-padding;
     }
     .uk-padding-large-h {
         padding-left: $padding-large-padding;
         padding-right: $padding-large-padding;
     }

     .uk-padding-xlarge {
         padding: $padding-xlarge-padding;
     }
     .uk-padding-xlarge-t {
         padding-top: $padding-xlarge-padding;
     }
     .uk-padding-xlarge-r {
         padding-right: $padding-xlarge-padding;
     }
     .uk-padding-xlarge-b {
         padding-bottom: $padding-xlarge-padding;
     }
     .uk-padding-xlarge-l {
         padding-left: $padding-xlarge-padding;
     }
     .uk-padding-xlarge-v {
         padding-top: $padding-xlarge-padding;
         padding-bottom: $padding-xlarge-padding;
     }
     .uk-padding-xlarge-h {
         padding-left: $padding-xlarge-padding;
         padding-right: $padding-xlarge-padding;
     }

     // Remove
     .uk-padding-remove {
         padding: 0 !important;
     }
     .uk-padding-remove-t {
         padding-top: 0 !important;
     }
     .uk-padding-remove-r {
         padding-right: 0 !important;
     }
     .uk-padding-remove-b {
         padding-bottom: 0 !important;
     }
     .uk-padding-remove-l {
         padding-left: 0 !important;
     }
     .uk-padding-remove-v {
         padding-top: 0 !important;
         padding-bottom: 0 !important;
     }
     .uk-padding-remove-h {
         padding-left: 0 !important;
         padding-right: 0 !important;
     }

     // Phone landscape and bigger
     @media (min-width: $breakpoint-small) {
         .uk-padding-xsmall\@s {
             padding: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-t\@s {
             padding-top: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-r\@s {
             padding-right: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-b\@s {
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-l\@s {
             padding-left: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-v\@s {
             padding-top: $padding-xsmall-padding;
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-h\@s {
             padding-left: $padding-xsmall-padding;
             padding-right: $padding-xsmall-padding;
         }

         .uk-padding-small\@s {
             padding: $padding-small-padding;
         }
         .uk-padding-small-t\@s {
             padding-top: $padding-small-padding;
         }
         .uk-padding-small-r\@s {
             padding-right: $padding-small-padding;
         }
         .uk-padding-small-b\@s {
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-l\@s {
             padding-left: $padding-small-padding;
         }
         .uk-padding-small-v\@s {
             padding-top: $padding-small-padding;
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-h\@s {
             padding-left: $padding-small-padding;
             padding-right: $padding-small-padding;
         }

         .uk-padding\@s {
             padding: $padding-padding;
         }
         .uk-padding-t\@s {
             padding-top: $padding-padding;
         }
         .uk-padding-r\@s {
             padding-right: $padding-padding;
         }
         .uk-padding-b\@s {
             padding-bottom: $padding-padding;
         }
         .uk-padding-l\@s {
             padding-left: $padding-padding;
         }
         .uk-padding-v\@s {
             padding-top: $padding-padding;
             padding-bottom: $padding-padding;
         }
         .uk-padding-h\@s {
             padding-left: $padding-padding;
             padding-right: $padding-padding;
         }

         .uk-padding-large\@s {
             padding: $padding-large-padding;
         }
         .uk-padding-large-t\@s {
             padding-top: $padding-large-padding;
         }
         .uk-padding-large-r\@s {
             padding-right: $padding-large-padding;
         }
         .uk-padding-large-b\@s {
             padding-bottom: $padding-large-padding;
         }
         .uk-padding-large-l\@s {
             padding-left: $padding-large-padding;
         }
         .uk-padding-large-v\@s {
             padding-top: $padding-large-padding;
             padding-bottom: $padding-large-padding;
         }
         .uk-padding-large-h\@s {
             padding-left: $padding-large-padding;
             padding-right: $padding-large-padding;
         }

         .uk-padding-xlarge\@s {
             padding: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-t\@s {
             padding-top: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-r\@s {
             padding-right: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-b\@s {
             padding-bottom: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-l\@s {
             padding-left: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-v\@s {
             padding-top: $padding-xlarge-padding;
             padding-bottom: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-h\@s {
             padding-left: $padding-xlarge-padding;
             padding-right: $padding-xlarge-padding;
         }


         .uk-padding-remove\@s {
             padding: 0 !important;
         }
         .uk-padding-remove-top\@s, .uk-padding-remove-t\@s {
             padding-top: 0 !important;
         }
         .uk-padding-remove-bottom\@s, .uk-padding-remove-b\@s {
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-left\@s, .uk-padding-remove-l\@s {
             padding-left: 0 !important;
         }
         .uk-padding-remove-right\@s, .uk-padding-remove-r\@s {
             padding-right: 0 !important;
         }
         .uk-padding-remove-vertical\@s, .uk-padding-remove-v\@s {
             padding-top: 0 !important;
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-horizontal\@s, .uk-padding-remove-h\@s {
             padding-left: 0 !important;
             padding-right: 0 !important;
         }

     }

     // Tablet landscape and bigger
     @media (min-width: $breakpoint-medium) {
         .uk-padding-xsmall\@m {
             padding: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-t\@m {
             padding-top: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-r\@m {
             padding-right: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-b\@m {
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-l\@m {
             padding-left: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-v\@m {
             padding-top: $padding-xsmall-padding;
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-h\@m {
             padding-left: $padding-xsmall-padding;
             padding-right: $padding-xsmall-padding;
         }

         .uk-padding-small\@m {
             padding: $padding-small-padding;
         }
         .uk-padding-small-t\@m {
             padding-top: $padding-small-padding;
         }
         .uk-padding-small-r\@m {
             padding-right: $padding-small-padding;
         }
         .uk-padding-small-b\@m {
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-l\@m {
             padding-left: $padding-small-padding;
         }
         .uk-padding-small-v\@m {
             padding-top: $padding-small-padding;
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-h\@m {
             padding-left: $padding-small-padding;
             padding-right: $padding-small-padding;
         }

         .uk-padding\@m {
             padding: $padding-padding;
         }
         .uk-padding-t\@m {
             padding-top: $padding-padding;
         }
         .uk-padding-r\@m {
             padding-right: $padding-padding;
         }
         .uk-padding-b\@m {
             padding-bottom: $padding-padding;
         }
         .uk-padding-l\@m {
             padding-left: $padding-padding;
         }
         .uk-padding-v\@m {
             padding-top: $padding-padding;
             padding-bottom: $padding-padding;
         }
         .uk-padding-h\@m {
             padding-left: $padding-padding;
             padding-right: $padding-padding;
         }

         .uk-padding-large\@m {
             padding: $padding-large-padding;
         }
         .uk-padding-large-t\@m {
             padding-top: $padding-large-padding;
         }
         .uk-padding-large-r\@m {
             padding-right: $padding-large-padding;
         }
         .uk-padding-large-b\@m {
             padding-bottom: $padding-large-padding;
         }
         .uk-padding-large-l\@m {
             padding-left: $padding-large-padding;
         }
         .uk-padding-large-v\@m {
             padding-top: $padding-large-padding;
             padding-bottom: $padding-large-padding;
         }
         .uk-padding-large-h\@m {
             padding-left: $padding-large-padding;
             padding-right: $padding-large-padding;
         }


         .uk-padding-xlarge\@m {
             padding: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-t\@m {
             padding-top: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-r\@m {
             padding-right: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-b\@m {
             padding-bottom: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-l\@m {
             padding-left: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-v\@m {
             padding-top: $padding-xlarge-padding;
             padding-bottom: $padding-xlarge-padding;
         }
         .uk-padding-xlarge-h\@m {
             padding-left: $padding-xlarge-padding;
             padding-right: $padding-xlarge-padding;
         }


         .uk-padding-remove\@m {
             padding: 0 !important;
         }
         .uk-padding-remove-top\@m, .uk-padding-remove-t\@m {
             padding-top: 0 !important;
         }
         .uk-padding-remove-bottom\@m, .uk-padding-remove-b\@m {
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-left\@m, .uk-padding-remove-l\@m {
             padding-left: 0 !important;
         }
         .uk-padding-remove-right\@m, .uk-padding-remove-r\@m {
             padding-right: 0 !important;
         }
         .uk-padding-remove-vertical\@m, .uk-padding-remove-v\@m {
             padding-top: 0 !important;
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-horizontal\@m, .uk-padding-remove-h\@m {
             padding-left: 0 !important;
             padding-right: 0 !important;
         }

     }

     // Desktop and bigger
     @media (min-width: $breakpoint-large) {
         .uk-padding-xsmall\@l {
             padding: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-t\@l {
             padding-top: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-r\@l {
             padding-right: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-b\@l {
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-l\@l {
             padding-left: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-v\@l {
             padding-top: $padding-xsmall-padding;
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-h\@l {
             padding-left: $padding-xsmall-padding;
             padding-right: $padding-xsmall-padding;
         }

         .uk-padding-small\@l {
             padding: $padding-small-padding;
         }
         .uk-padding-small-t\@l {
             padding-top: $padding-small-padding;
         }
         .uk-padding-small-r\@l {
             padding-right: $padding-small-padding;
         }
         .uk-padding-small-b\@l {
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-l\@l {
             padding-left: $padding-small-padding;
         }
         .uk-padding-small-v\@l {
             padding-top: $padding-small-padding;
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-h\@l {
             padding-left: $padding-small-padding;
             padding-right: $padding-small-padding;
         }

         // begin leave @l off - these need to responsive change to higher padding at desktops
         .uk-padding {
             padding: $padding-padding-l;
         }
         // $padding-padding-l;
         .uk-padding-t {
             padding-top: $padding-padding-l;
         }
         .uk-padding-r {
             padding-right: $padding-padding-l;
         }
         .uk-padding-b {
             padding-bottom: $padding-padding-l;
         }
         .uk-padding-l {
             padding-left: $padding-padding-l;
         }
         .uk-padding-v {
             padding-top: $padding-padding-l;
             padding-bottom: $padding-padding-l;
         }
         .uk-padding-h {
             padding-left: $padding-padding-l;
             padding-right: $padding-padding-l;
         }

         .uk-padding-large {
             padding: $padding-large-padding-l;
         }
         // $padding-large-padding-l;
         .uk-padding-large-t {
             padding-top: $padding-large-padding-l;
         }
         .uk-padding-large-r {
             padding-right: $padding-large-padding-l;
         }
         .uk-padding-large-b {
             padding-bottom: $padding-large-padding-l;
         }
         .uk-padding-large-l {
             padding-left: $padding-large-padding-l;
         }
         .uk-padding-large-v {
             padding-top: $padding-large-padding-l;
             padding-bottom: $padding-large-padding-l;
         }
         .uk-padding-large-h {
             padding-left: $padding-large-padding-l;
             padding-right: $padding-large-padding-l;
         }

         .uk-padding-xlarge {
             padding: $padding-xlarge-padding-l;
         }
         // $padding-large-padding-l;
         .uk-padding-xlarge-t {
             padding-top: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-r {
             padding-right: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-b {
             padding-bottom: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-l {
             padding-left: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-v {
             padding-top: $padding-xlarge-padding-l;
             padding-bottom: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-h {
             padding-left: $padding-xlarge-padding-l;
             padding-right: $padding-xlarge-padding-l;
         }

         // end leave @l off

         .uk-padding-remove\@l {
             padding: 0 !important;
         }
         .uk-padding-remove-top\@l, .uk-padding-remove-t\@l {
             padding-top: 0 !important;
         }
         .uk-padding-remove-bottom\@l, .uk-padding-remove-b\@l {
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-left\@l, .uk-padding-remove-l\@l {
             padding-left: 0 !important;
         }
         .uk-padding-remove-right\@l, .uk-padding-remove-r\@l {
             padding-right: 0 !important;
         }
         .uk-padding-remove-vertical\@l, .uk-padding-remove-v\@l {
             padding-top: 0 !important;
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-horizontal\@l, .uk-padding-remove-h\@l {
             padding-left: 0 !important;
             padding-right: 0 !important;
         }

     }

     // Large screen and bigger
     @media (min-width: $breakpoint-xlarge) {
         .uk-padding-xsmall\@xl {
             padding: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-t\@xl {
             padding-top: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-r\@xl {
             padding-right: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-b\@xl {
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-l\@xl {
             padding-left: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-v\@xl {
             padding-top: $padding-xsmall-padding;
             padding-bottom: $padding-xsmall-padding;
         }
         .uk-padding-xsmall-h\@xl {
             padding-left: $padding-xsmall-padding;
             padding-right: $padding-xsmall-padding;
         }

         .uk-padding-small\@xl {
             padding: $padding-small-padding;
         }
         .uk-padding-small-t\@xl {
             padding-top: $padding-small-padding;
         }
         .uk-padding-small-r\@xl {
             padding-right: $padding-small-padding;
         }
         .uk-padding-small-b\@xl {
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-l\@xl {
             padding-left: $padding-small-padding;
         }
         .uk-padding-small-v\@xl {
             padding-top: $padding-small-padding;
             padding-bottom: $padding-small-padding;
         }
         .uk-padding-small-h\@xl {
             padding-left: $padding-small-padding;
             padding-right: $padding-small-padding;
         }

         // begin leave @l off - these need to responsive change to higher padding at desktops
         .uk-padding {
             padding: $padding-padding-l;
         }
         // $padding-padding-l;
         .uk-padding-t {
             padding-top: $padding-padding-l;
         }
         .uk-padding-r {
             padding-right: $padding-padding-l;
         }
         .uk-padding-b {
             padding-bottom: $padding-padding-l;
         }
         .uk-padding-l {
             padding-left: $padding-padding-l;
         }
         .uk-padding-v {
             padding-top: $padding-padding-l;
             padding-bottom: $padding-padding-l;
         }
         .uk-padding-h {
             padding-left: $padding-padding-l;
             padding-right: $padding-padding-l;
         }

         .uk-padding-large {
             padding: $padding-large-padding-l;
         }
         // $padding-large-padding-l;
         .uk-padding-large-t {
             padding-top: $padding-large-padding-l;
         }
         .uk-padding-large-r {
             padding-right: $padding-large-padding-l;
         }
         .uk-padding-large-b {
             padding-bottom: $padding-large-padding-l;
         }
         .uk-padding-large-l {
             padding-left: $padding-large-padding-l;
         }
         .uk-padding-large-v {
             padding-top: $padding-large-padding-l;
             padding-bottom: $padding-large-padding-l;
         }
         .uk-padding-large-h {
             padding-left: $padding-large-padding-l;
             padding-right: $padding-large-padding-l;
         }


         .uk-padding-large {
             padding: $padding-xlarge-padding-l;
         }
         // $padding-large-padding-l;
         .uk-padding-xlarge-t {
             padding-top: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-r {
             padding-right: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-b {
             padding-bottom: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-l {
             padding-left: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-v {
             padding-top: $padding-xlarge-padding-l;
             padding-bottom: $padding-xlarge-padding-l;
         }
         .uk-padding-xlarge-h {
             padding-left: $padding-xlarge-padding-l;
             padding-right: $padding-xlarge-padding-l;
         }

         // end leave @l off

         .uk-padding-remove\@xl {
             padding: 0 !important;
         }
         .uk-padding-remove-top\@xl, .uk-padding-remove-t\@xl {
             padding-top: 0 !important;
         }
         .uk-padding-remove-bottom\@xl, .uk-padding-remove-b\@xl {
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-left\@xl, .uk-padding-remove-l\@xl {
             padding-left: 0 !important;
         }
         .uk-padding-remove-right\@xl, .uk-padding-remove-r\@xl {
             padding-right: 0 !important;
         }
         .uk-padding-remove-vertical\@xl, .uk-padding-remove-v\@xl {
             padding-top: 0 !important;
             padding-bottom: 0 !important;
         }
         .uk-padding-remove-horizontal\@xl, .uk-padding-remove-h\@xl {
             padding-left: 0 !important;
             padding-right: 0 !important;
         }

     }
 }
