// Name:            Heading
// Description:     Styles for headings
//
// Component:       `uk-heading-primary`
//                  `uk-heading-hero`
//                  `uk-heading-divider`
//                  `uk-heading-bullet`
//                  `uk-heading-line`
//
// ========================================================================


// Variables
// ========================================================================

$heading-medium-font-size-l:                     3rem !default;                                   // 64px
$heading-large-font-size-l:                      3.8888889rem !default;                                   // 96px
$heading-xlarge-font-size-l:                     6rem !default;                                   // 128px
$heading-2xlarge-font-size-l:                    8rem !default;                                  // 176px

$heading-small-font-size-m:                      $heading-medium-font-size-l * 0.8125 !default;   // 3.25rem / 52px
$heading-medium-font-size-m:                     $heading-medium-font-size-l * 0.875 !default;    // 3.5rem / 56px
$heading-large-font-size-m:                      $heading-medium-font-size-l !default;            // 4rem / 64px
$heading-xlarge-font-size-m:                     $heading-large-font-size-l !default;             // 6rem / 96px
$heading-2xlarge-font-size-m:                    $heading-xlarge-font-size-l !default;            // 8rem / 128px

$heading-small-font-size:                        $heading-small-font-size-m * 0.8 !default;      // 38px 0.73
$heading-medium-font-size:                       $heading-medium-font-size-m * 0.825 !default;     // 40px 0.714
$heading-large-font-size:                        $heading-large-font-size-m * 0.85 !default;      // 50px 0.78
$heading-xlarge-font-size:                       $heading-large-font-size-m !default;             // 4rem / 64px
$heading-2xlarge-font-size:                      $heading-xlarge-font-size-m !default;            // 6rem / 96px





$heading-small-line-height:                      1.2 !default;
$heading-medium-line-height:                     1.1 !default;
$heading-large-line-height:                      1.1 !default;
$heading-xlarge-line-height:                     1 !default;
$heading-2xlarge-line-height:                    1 !default;

$heading-divider-padding-bottom:                 unquote('calc(5px + 0.1em)') !default;
$heading-divider-border-width:                   unquote('calc(0.2px + 0.05em)') !default;
$heading-divider-border:                         $global-border !default;

$heading-bullet-top:                             unquote('calc(-0.1 * 1em)') !default;
$heading-bullet-height:                          unquote('calc(4px + 0.7em)') !default;
$heading-bullet-margin-right:                    unquote('calc(5px + 0.2em)') !default;
$heading-bullet-border-width:                    unquote('calc(5px + 0.1em)') !default;
$heading-bullet-border:                          $global-border !default;

$heading-line-top:                               50% !default;
$heading-line-border-width:                      unquote('calc(0.2px + 0.05em)') !default;
$heading-line-height:                            $heading-line-border-width !default;
$heading-line-width:                             2000px !default;

$heading-line-border:                            $global-border !default;
$heading-line-margin-horizontal:                 unquote('calc(5px + 0.3em)') !default;



// Inverse
// ========================================================================

$inverse-heading-divider-border:                   $inverse-global-border !default;
$inverse-heading-bullet-border:                    $inverse-global-border !default;
$inverse-heading-line-border:                      $inverse-global-border !default;

