// Name:            Tab
// Description:     Component to create a tabbed navigation
//
// Component:       `uk-tab`
//
// Modifiers:       `uk-tab-bottom`
//                  `uk-tab-left`
//                  `uk-tab-right`
//
// States:          `uk-active`
//                  `uk-disabled`
//
// ========================================================================


// Hooks
// ========================================================================


 @mixin hook-tab(){
   margin-bottom: 0;

   & > * {
   @include breakpoint($breakpoint-small) {
     padding-right: 6px!important;
   }
   }
 }
 @mixin hook-tab-item(){
   background-color: $global-muted-background;
   text-decoration: none;
   font-size: $global-tiny-font-size;
   font-weight: $semibold;


   @include breakpoint($breakpoint-small) {
     font-size: $global-standard-font-size;
     padding: 5px 10px;
   }


   &.large {
    @include breakpoint($breakpoint-medium) {
      padding: ($tab-item-padding-vertical + 7px) ($tab-item-padding-horizontal + 14px)
    }
   }
 }
 @mixin hook-tab-item-hover(){
   background-color: $global-emphasis-color;

 }
 @mixin hook-tab-item-active(){
   background-color: $global-emphasis-color;

 }
// @mixin hook-tab-item-disabled(){}
// @mixin hook-tab-bottom(){}
// @mixin hook-tab-bottom-item(){}
// @mixin hook-tab-left(){}
// @mixin hook-tab-left-item(){}
// @mixin hook-tab-right(){}
// @mixin hook-tab-right-item(){}
// @mixin hook-tab-misc(){}


// Inverse
// ========================================================================



// @mixin hook-inverse-tab(){}
// @mixin hook-inverse-tab-item(){}
// @mixin hook-inverse-tab-item-hover(){}
// @mixin hook-inverse-tab-item-active(){}
// @mixin hook-inverse-tab-item-disabled(){}