.ce-hero {
  background: linear-gradient(190.52deg, #2562AB 0%, #004078 26.32%, #000732 100%);

  .pretitle {
   font-size: $global-small-font-size;
   font-weight: $bold;
   text-transform: none;
   margin-bottom: 1em;
    letter-spacing: 0;

   @include breakpoint($breakpoint-medium) {
     font-size: 1.111111rem;
   }
 }

  .uk-text-lead {
    margin-top: 20px;
    @include breakpoint($breakpoint-medium) {
      margin-top: $global-medium-margin;
    }
  }


}

