// Name:            Slidenav
// Description:     Component to create previous/next icon navigations
//
// Component:       `uk-slidenav`
//
// Sub-objects:     `uk-slidenav-container`
//
// Modifiers:       `uk-slidenav-previous`
//                  `uk-slidenav-next`
//                  `uk-slidenav-large`
//
// ========================================================================



// Hooks
// ========================================================================


// @mixin hook-slidenav(){}
// @mixin hook-slidenav-hover(){}
// @mixin hook-slidenav-active(){}
 @mixin hook-slidenav-previous(){
  svg {
   display: none;
  }
  //background: transparent inline-svg('arrow_small_left',#0000FF) no-repeat center;
  background-size: contain;
  display: block;
  width: .8em!important;
  height: .8em;
  margin-right: 1.3em;
 }
 @mixin hook-slidenav-next(){
  svg {
   display: none;
  }
  //background: transparent inline-svg('arrow_small_right',#0000FF) no-repeat center;
  background-size: contain;
  display: block;
  width: .8em!important;
  height: .8em;
  margin-left: 1.3em;
 }
// @mixin hook-slidenav-large(){}
// @mixin hook-slidenav-container(){}
// @mixin hook-slidenav-misc(){}


// Inverse
// ========================================================================


// @mixin hook-inverse-slidenav(){}
// @mixin hook-inverse-slidenav-hover(){}
// @mixin hook-inverse-slidenav-active(){}
