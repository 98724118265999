// Name:            Breadcrumb
// Description:     Component to create a breadcrumb navigation
//
// Component:       `uk-breadcrumb`
//
// States:          `uk-disabled`
//
// ========================================================================


// Hooks
// ========================================================================


// @mixin hook-breadcrumb(){}
 @mixin hook-breadcrumb-item(){
   font-style: italic;
   letter-spacing: .5px;
 }
// @mixin hook-breadcrumb-item-hover(){}
// @mixin hook-breadcrumb-item-disabled(){}
// @mixin hook-breadcrumb-item-active(){}
// @mixin hook-breadcrumb-divider(){}
// @mixin hook-breadcrumb-misc(){}


// Inverse
// ========================================================================


// @mixin hook-inverse-breadcrumb-item(){}
// @mixin hook-inverse-breadcrumb-item-hover(){}
// @mixin hook-inverse-breadcrumb-item-disabled(){}
// @mixin hook-inverse-breadcrumb-item-active(){}
// @mixin hook-inverse-breadcrumb-divider(){}
