// Name:            Button
// Description:     Styles for buttons
//
// Component:       `uk-button`
//
// Sub-objects:     `uk-button-group`
//
// Modifiers:       `uk-button-default`
//                  `uk-button-primary`
//                  `uk-button-secondary`
//                  `uk-button-danger`
//                  `uk-button-text`
//                  `uk-button-link`
//                  `uk-button-small`
//                  `uk-button-large`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$button-line-height:                             $global-control-height !default;
$button-small-line-height:                       $global-control-small-height !default;
$button-large-line-height:                       $global-control-large-height !default;

$button-font-size:                               $global-standard-font-size !default;
$button-small-font-size:                         $global-standard-font-size !default;
$button-large-font-size:                         $global-small-font-size !default;

$button-padding-horizontal:                      20px !default;
$button-small-padding-horizontal:                $global-gutter !default;
$button-large-padding-horizontal:                $global-gutter !default;

$button-default-background:                      transparent !default;
$button-default-color:                           $global-emphasis-color !default;
$button-default-hover-background:                $global-emphasis-color !default;
$button-default-hover-color:                     $global-inverse-color !default;
$button-default-active-background:               $global-secondary-color !default;
$button-default-active-color:                    $global-inverse-color !default;

$button-primary-background:                      $global-primary-background !default;
$button-primary-color:                           $global-inverse-color !default;
$button-primary-hover-background:                $global-primary-background !default;
$button-primary-hover-color:                     $global-inverse-color !default;
$button-primary-active-background:               $global-primary-background !default;
$button-primary-active-color:                    $global-inverse-color !default;

$button-secondary-background:                    $global-secondary-background !default;
$button-secondary-color:                         $global-inverse-color !default;
$button-secondary-hover-background:              $global-primary-background !default;
$button-secondary-hover-color:                   $global-inverse-color !default;
$button-secondary-active-background:             $global-primary-background !default;
$button-secondary-active-color:                  $global-inverse-color !default;

$button-danger-background:                       $global-danger-background !default;
$button-danger-color:                            $global-inverse-color !default;
$button-danger-hover-background:                 darken($button-danger-background, 5%) !default;
$button-danger-hover-color:                      $global-inverse-color !default;
$button-danger-active-background:                darken($button-danger-background, 10%) !default;
$button-danger-active-color:                     $global-inverse-color !default;

$button-disabled-background:                     #CCCFDC !default;
$button-disabled-color:                          $global-inverse-color !default;

$button-text-line-height:                        $global-line-height !default;
$button-text-color:                              $global-secondary-color !default;
$button-text-hover-color:                        $global-emphasis-color !default;
$button-text-disabled-color:                     $global-muted-color !default;

$button-link-line-height:                        $global-line-height !default;
$button-link-color:                              $global-link-color !default;
$button-link-hover-color:                        $global-link-hover-color !default;
$button-link-hover-text-decoration:              underline !default;
$button-link-disabled-color:                     $global-muted-color !default;



// Inverse
// ========================================================================

$inverse-button-default-background:             transparent !default;
$inverse-button-default-color:                  $global-inverse-color !default;
$inverse-button-default-hover-background:       $global-inverse-color !default;
$inverse-button-default-hover-color:            $inverse-global-inverse-color !default;
$inverse-button-default-active-background:      $global-inverse-color !default;
$inverse-button-default-active-color:           $inverse-global-inverse-color !default;


$inverse-button-primary-background:             $button-primary-background !default;
$inverse-button-primary-color:                  $global-color !default;
$inverse-button-primary-hover-background:       $inverse-global-color !default;
$inverse-button-primary-hover-color:            $global-primary-background !default;
$inverse-button-primary-active-background:      darken($inverse-button-primary-background, 10%) !default;
$inverse-button-primary-active-color:           $inverse-global-inverse-color !default;

$inverse-button-secondary-background:           $global-secondary-background !default;
$inverse-button-secondary-color:                $inverse-global-color !default;
$inverse-button-secondary-hover-background:     $inverse-global-color !default;
$inverse-button-secondary-hover-color:          $global-secondary-background !default;
$inverse-button-secondary-active-background:    $inverse-global-color !default;
$inverse-button-secondary-active-color:         $global-secondary-background !default;

$inverse-button-text-color:                     $inverse-global-muted-color !default;
$inverse-button-text-hover-color:               $inverse-global-color !default;
$inverse-button-text-disabled-color:            $inverse-global-muted-color !default;
$inverse-button-link-color:                     $inverse-global-muted-color !default;
$inverse-button-link-hover-color:               $inverse-global-color !default;
