// Name:            Accordion
// Description:     Component to create accordions
//
// Component:       `uk-accordion`
//
// Sub-objects:     `uk-accordion-title`
//                  `uk-accordion-content`
//
// States:          `uk-open`
//
// ========================================================================


// Variables
// ========================================================================

$accordion-item-margin-top:                     0 !default;

$accordion-title-font-size:                     1.111111rem !default;
$accordion-title-line-height:                   1.4 !default;
$accordion-title-color:                         $global-color !default;
$accordion-title-hover-color:                   $global-color !default;

$accordion-content-margin-top:                  0px !default;

$accordion-icon-margin-left:                    30px !default;
$accordion-icon-color: $global-emphasis-color !default;
$internal-accordion-open-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-accordion-close-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;


// Inverse
// ========================================================================

$inverse-accordion-title-color:              $inverse-global-emphasis-color !default;
$inverse-accordion-title-hover-color:        $inverse-global-color !default;