// Variables
// ========================================================================
// Name:            Container
// Description:     Component to align and center your site and grid content
//
// Component:       `uk-container`
//
// Modifier:        `uk-container-small`
//                  `uk-container-large`
//                  `uk-container-expand`
//                  `uk-container-expand-left`
//                  `uk-container-expand-right`
//                  `uk-container-item-padding-remove-left`
//                  `uk-container-item-padding-remove-right`
//
// ========================================================================


// Variables
// ========================================================================


$container-max-width:                    1300px !default;
$container-xsmall-max-width:             750px !default;
$container-small-max-width:              860px !default;
$container-large-max-width:              1960px !default;
$container-xlarge-max-width:             2400px !default;

$container-padding-horizontal:           15px !default;
$container-padding-horizontal-s:         $global-gutter !default;
$container-padding-horizontal-m:         $global-medium-gutter !default;

// Miscellaneous
// ========================================================================

// @mixin hook-container-misc(){}