// Name:            Subnav
// Description:     Component to create a sub navigation
//
// Component:       `uk-subnav`
//
// Modifiers:       `uk-subnav-divider`
//                  `uk-subnav-pill`
//
// States:          `uk-active`
//                  `uk-first-column`
//
// ========================================================================


// @mixin hook-subnav(){}
// @mixin hook-subnav-item(){}
// @mixin hook-subnav-item-hover(){}
// @mixin hook-subnav-item-active(){}
// @mixin hook-subnav-divider(){}
 @mixin hook-subnav-pill-item(){

 }
// @mixin hook-subnav-pill-item-hover(){}
// @mixin hook-subnav-pill-item-onclick(){}
// @mixin hook-subnav-pill-item-active(){}
// @mixin hook-subnav-item-disabled(){}
@mixin hook-subnav-misc(){
 .uk-subnav-pill .uk-margin-small-top {
  margin-top: 10px!important;
 }
}


// Inverse
// ========================================================================

// @mixin hook-inverse-subnav-item(){}
// @mixin hook-inverse-subnav-item-hover(){}
// @mixin hook-inverse-subnav-item-active(){}
// @mixin hook-inverse-subnav-divider(){}
// @mixin hook-inverse-subnav-pill-item(){}
// @mixin hook-inverse-subnav-pill-item-hover(){}
// @mixin hook-inverse-subnav-pill-item-onclick(){}
// @mixin hook-inverse-subnav-pill-item-active(){}
// @mixin hook-inverse-subnav-item-disabled(){}