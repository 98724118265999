// Name:            Slidenav
// Description:     Component to create previous/next icon navigations
//
// Component:       `uk-slidenav`
//
// Sub-objects:     `uk-slidenav-container`
//
// Modifiers:       `uk-slidenav-previous`
//                  `uk-slidenav-next`
//                  `uk-slidenav-large`
//
// ========================================================================


// Variables
// ========================================================================

$slidenav-padding-vertical:                      5px !default;
$slidenav-padding-horizontal:                    10px !default;

$slidenav-color:                                 $global-color !default;
$slidenav-hover-color:                           $global-emphasis-color !default;
$slidenav-active-color:                          $global-emphasis-color !default;

$slidenav-large-padding-vertical:                15px !default;
$slidenav-large-padding-horizontal:              $slidenav-large-padding-vertical !default;



// Inverse
// ========================================================================

$inverse-slidenav-color:                        rgba($inverse-global-color, 0.7) !default;
$inverse-slidenav-hover-color:                  rgba($inverse-global-color, 0.95) !default;
$inverse-slidenav-active-color:                 rgba($inverse-global-color, 0.7) !default;
