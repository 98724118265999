// Name:            Breadcrumb
// Description:     Component to create a breadcrumb navigation
//
// Component:       `uk-breadcrumb`
//
// States:          `uk-disabled`
//
// ========================================================================


// Variables
// ========================================================================

$breadcrumb-item-font-size:                          .777778rem !default;
$breadcrumb-item-color:                              $global-muted-color !default;
$breadcrumb-item-hover-color:                        $global-color !default;
$breadcrumb-item-hover-text-decoration:              none !default;
$breadcrumb-item-active-color:                       $global-color !default;

$breadcrumb-divider:                                 ">" !default;
$breadcrumb-divider-margin-horizontal:               4px !default;
$breadcrumb-divider-font-size:                       $breadcrumb-item-font-size !default;
$breadcrumb-divider-color:                           $global-muted-color !default;


// Inverse
// ========================================================================

$inverse-breadcrumb-item-color:                 $inverse-global-muted-color !default;
$inverse-breadcrumb-item-hover-color:           $inverse-global-color !default;
$inverse-breadcrumb-item-active-color:          $inverse-global-color !default;
$inverse-breadcrumb-divider-color:              $inverse-global-muted-color !default;

