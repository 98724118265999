@mixin svg-symbol($name) {
  //background: url('data:image/svg+xml;utf8,<svg><use xlink:href="../images/symbols.svg##{$name}"></svg>') no-repeat;                               ##{$name}
  background: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cuse xlink:href='../images/symbols.svg%23#{$name}'/%3E%3C/svg%3E");
  //background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='5' y='5' width='5' height='5'/><use xlink:href='../images/symbols.svg%23DeutschmannLogo'/></svg>");
}

@mixin btn-shadow($v:1) {
  $alpha: $v * .2;
  box-shadow: #{.1em * $v} #{.1em * $v} #{.7em * $v} rgba(0, 0, 0, $alpha);
}

@mixin clearfix {
  zoom:1;
  &:before, &:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }
  &:after {
    clear: both;
  }
}

@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image:    -moz-linear-gradient(top, $startColor, $endColor);
  background-image:     -ms-linear-gradient(top, $startColor, $endColor);
  background-image:      -o-linear-gradient(top, $startColor, $endColor);
  background-image:         linear-gradient(top, $startColor, $endColor);
  filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

@mixin background-size($width: 100%, $height: 100%) {
  -moz-background-size: $width $height;
  -webkit-background-size: $width $height;
  background-size: $width $height;
}

@mixin border-radius($radius: 5px) {
  -moz-border-radius:    $radius;
  -webkit-border-radius: $radius;
  border-radius:         $radius;
}

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
  -webkit-border-top-left-radius:     $topLeftRadius;
  -webkit-border-top-right-radius:    $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius:  $bottomLeftRadius;

  -moz-border-radius-topleft:     $topLeftRadius;
  -moz-border-radius-topright:    $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft:  $bottomLeftRadius;

  border-top-left-radius:     $topLeftRadius;
  border-top-right-radius:    $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius:  $bottomLeftRadius;
}

@mixin box($orient: horizontal, $pack: center, $align: center) {
  display: -webkit-box;
  display: -moz-box;
  display: box;

  -webkit-box-orient: $orient;
  -moz-box-orient:    $orient;
  box-orient:         $orient;

  -webkit-box-pack: $pack;
  -moz-box-pack:    $pack;
  box-pack:         $pack;

  -webkit-box-align: $align;
  -moz-box-align:    $align;
  box-align:         $align;
}

@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
  background-color: transparent;
  background-color: rgba($r, $g, $b, $opacity);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
  zoom:   1;
}

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
  @if $inset != "" {
    -webkit-box-shadow: $inset $x $y $blur $color;
    -moz-box-shadow:    $inset $x $y $blur $color;
    box-shadow:         $inset $x $y $blur $color;
  } @else {
    -webkit-box-shadow: $x $y $blur $color;
    -moz-box-shadow:    $x $y $blur $color;
    box-shadow:         $x $y $blur $color;
  }
}

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing:   $type;
  -moz-box-sizing:      $type;
  box-sizing:           $type;
}

@mixin columns($count: 3, $gap: 10) {
  -webkit-column-count: $count;
  -moz-column-count:    $count;
  column-count:         $count;

  -webkit-column-gap:   $gap;
  -moz-column-gap:      $gap;
  column-gap:           $gap;
}

@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
  border: 1px solid $colorOne;

  -webkit-box-shadow: 0 0 0 1px $colorTwo;
  -moz-box-shadow:    0 0 0 1px $colorTwo;
  box-shadow:         0 0 0 1px $colorTwo;

  @include border-radius( $radius );
}

@mixin flex($value: 1) {
  -webkit-box-flex: $value;
  -moz-box-flex:    $value;
  box-flex:         $value;
}

@mixin flip($scaleX: -1) {
  -moz-transform:    scaleX($scaleX);
  -o-transform:      scaleX($scaleX);
  -webkit-transform: scaleX($scaleX);
  transform:         scaleX($scaleX);
  filter:            FlipH;
  -ms-filter:        "FlipH";
}

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
// =============================================================================
// Font Face
// =============================================================================
@mixin font-face($name, $filename, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $path: "../fonts/" + $filename;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin outline-radius($radius: 5px) {
  -webkit-outline-radius: $radius;
  -moz-outline-radius:    $radius;
  outline-radius:         $radius;
}

@mixin resize($direction: both) {
  -webkit-resize: $direction;
  -moz-resize:    $direction;
  resize:         $direction;
}

@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
  -moz-transform: rotate($deg + deg);
  -o-transform: rotate($deg + deg);
  -webkit-transform: rotate($deg + deg);
  -ms-transform: rotate($deg + deg);
  transform: rotate($deg + deg);
  filter: progid:DXImageTransform.Microsoft.Matrix(
                  M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
  zoom: 1;
}

@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
  text-shadow: $x $y $blur $color;
}

@mixin transform($params) {
  -webkit-transform: $params;
  -moz-transform:    $params;
  transform:         $params;
}

@mixin transition($declarations...) {
  @each $prefix in '-webkit-', '-moz-', '-ms-', '-o-', '' {
    #{$prefix}transition: $declarations;
  }
}

@mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
  border: 1px solid $colorOne;

  @include border-radius($radius);

  -webkit-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  -moz-box-shadow:    0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  box-shadow:         0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
}



@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin animation($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -ms-animation: #{$animation};
  animation: #{$animation};
}
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}
@mixin pie-clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
%pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

//
// REM mixin by bitmanic
// https://github.com/bitmanic/rem
//
@mixin rem($property, $px-values) {
  // Convert the baseline into rems
  $baseline-rem: $baseline-px / 1rem * 1;
  // Print the first line in pixel values
  #{$property}: $px-values;
  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {
    #{$property}: $px-values / $baseline-rem; }
  @else {
    // Create an empty list that we can dump values into
    $rem-values: ();
    @each $value in $px-values {
      // If the value is zero or not a number, return it
      @if $value == 0 or type-of( $value ) != "number" {
        $rem-values: append($rem-values, $value); }
      @else {
        $rem-values: append($rem-values, $value / $baseline-rem); } }
    // Return the property and its list of converted values
    #{$property}: $rem-values; } }


@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}


// Responsive image
//
// Keep images from scaling beyond the width of their parents.
@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}


@mixin word-wrap() {
  word-break: break-all;
  word-break:     break-word;
  hyphens:         auto;
}

@mixin no-word-wrap() {
  word-break:     normal;
  -webkit-hyphens: manual;
  -moz-hyphens:    manual;
  hyphens:         manual;
}


$minwidths: 80;
@for $i from 1 through $minwidths {
  .mw-#{($i * 10)} {
    min-width: ($i * 10) + px;
  }
}


